<template>
        <div class="navbar-nav d-flex align-items-center w-100">
            <router-link data-bs-dismiss="offcanvas" to="/"><a class="nav-link active" aria-current="page" href="/">{{ lang.navbar.homepage }}</a></router-link>
            <router-link data-bs-dismiss="offcanvas" to="/services"><a class="nav-link active" href="/services">{{ lang.navbar.services }}</a></router-link>
            <router-link data-bs-dismiss="offcanvas" to="/about"><a class="nav-link active" href="/about">{{ lang.navbar.about }}</a></router-link>
            <router-link data-bs-dismiss="offcanvas" to="/contact"><a class="nav-link active" href="/contact">{{ lang.navbar.contact }}</a></router-link>
            <!-- Navbar-end START -->


            <!-- Change Language START -->
              <div v-if="true" class="dropdown exist-1024">
                <button style="padding-top:8px; padding-bottom:8px;" class="btn-light nav-link active dropdown-toggle bg-transparent border-0 text-light" type="button" id="chooseLanguageDropdown" data-bs-toggle="dropdown" aria-expanded="false" >EN</button>
                <ul class="dropdown-menu" style="width:100px;" aria-labelledby="chooseLanguageDropdown">
                  <li class="d-flex" style="width:100px;">
                    <button data-bs-dismiss="offcanvas"
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('en')"
                    >English</button>
                  </li>
                  <li class="d-flex" style="width:100px;">
                    <button data-bs-dismiss="offcanvas"
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('ru')"
                    >Russian</button>
                  </li>
                  <li class="d-flex" style="width:100px;">
                    <button data-bs-dismiss="offcanvas"
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('ar')"
                    >Arabic</button>
                  </li>
                </ul>
            </div>
            <!-- Change Language END -->
            <!-- PHONE SECTION START -->
              <a href="tel:+905448392408" class="nav-link active px-0 text-centerd-flex justify-content-center exist-1024" target="_blank" >
                <span class="align-self-center justify-content-end text-light row fw-700">
                    <span class="align-self-center fs-4">
                        <i class="shaking fa-solid fa-phone p-0 m-0"></i>
                    </span>
                </span>
              </a>
            <!-- PHONE SECTION END -->
          
        </div>
         <!-- Navbar-end END -->

</template>

<style scoped>
    @media screen and (max-width:1024px) {
        .active, .nav-link{
        
        text-align: center;
        background-color:transparent;
        color:white !important;
        font-size: 20px;
        border-bottom: 1px solid white !important;
        width: 30vh;
        }
    }
</style>

<script>
export default {
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>