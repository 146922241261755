<template>
  <div class="row d-flex justify-content-center mt-5">
    <div class="row d-flex justify-content-center why-us-container">
      <why-us-card
        icon="fa-solid fa-passport"
        :title="lang.component.whyUsSection.title[0]"
        :content="lang.component.whyUsSection.content[0]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-building-wheat"
        :title="lang.component.whyUsSection.title[1]"
        :content="lang.component.whyUsSection.content[1]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-user"
        :title="lang.component.whyUsSection.title[2]"
        :content="lang.component.whyUsSection.content[2]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-star-and-crescent"
        :title="lang.component.whyUsSection.title[3]"
        :content="lang.component.whyUsSection.content[3]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-handshake"
        :title="lang.component.whyUsSection.title[4]"
        :content="lang.component.whyUsSection.content[4]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-calendar-check"
        :title="lang.component.whyUsSection.title[5]"
        :content="lang.component.whyUsSection.content[5]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-star"
        :title="lang.component.whyUsSection.title[6]"
        :content="lang.component.whyUsSection.content[6]"
      ></why-us-card>
      <why-us-card
        icon="fa-solid fa-africa fa-list-check"
        :title="lang.component.whyUsSection.title[7]"
        :content="lang.component.whyUsSection.content[7]"
      ></why-us-card>
      <why-us-card v-if="false" title content></why-us-card>
    </div>
  </div>
</template>

<style scoped>
.why-us-container{
    max-width: 1000px;
}
</style>

<script>
export default {
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>