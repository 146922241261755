<template>
        <div class="line"></div>
</template>

<style scoped>
    .line
    {
        width: 81px;
        height: 2px;
        /* background-color:#02b2ee; */
        background-color:#1b2e4d;
        display: inline-block;
        box-sizing: border-box;
        vertical-align:middle;
        margin-bottom:12px;
        padding: 0px;
        margin: 0px;
    }
</style>
