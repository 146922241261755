import { createRouter, createWebHistory } from 'vue-router'
import index from './views/index'
import about from './views/about'
import contact from './views/contact'
import ListPropertiesEn from './views/list-properties'
import PropertyEn from './views/property'
import FaqEn from './views/faq'
import services from './views/services'
import PartnershipEn from './views/partnership'
import fallback from './views/fallback'

// Services
import citizenshipService from './views/services/citizenship-service'
import residencyService from './views/services/residency-service'
import companyEstablishmentService from './views/services/company-establishment-service'
import turkuazCardService from './views/services/turkuaz-card-service'
import moneyTransferService from './views/services/money-transfer-service'
import immigrationProblemsService from './views/services/immigration-problems-service'
import investmentConsultancyService from './views/services/investment-consultancy-and-tax-law-service'
import interpolCaseService from './views/services/interpol-case-service'
import realEstateLawService from './views/services/real-estate-law-service'
import commerceAndMaritimeLawService from './views/services/commerce-and-maritime-law-service'
import familyLawService from './views/services/family-law-service'
import RealEstatePurchaseAndBrokerageService from './views/services/real-estate-purchase-and-brokerage-service'

const routes = [
  {
    path: '/404',
    name: 'fallback',
    component: fallback,
    meta:{
      title: '404 | Viya Legal Citizenhsip & Investment Services'
    }
  },
  {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: "Viya Legal Citizenship & Investment Consultant Services"
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      title: "About | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      title: "Contact | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/services',
    name: 'services',
    component: services,
    meta: {
      title: "Services | Viya Legal Citizenship & Investment Services"
    }
  }
  ,
  {
    path: '/citizenship-service',
    name: 'citizenship-service',
    component: citizenshipService,
    meta: {
      title: "Citizenship | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/residency-service',
    name: 'residency-service',
    component: residencyService,
    meta: {
      title: "Residency | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/company-establishment-service',
    name: 'company-establishment-service',
    component: companyEstablishmentService,
    meta: {
      title: "Company Establishment | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/turkuaz-card-service',
    name: 'turkuaz-card-service',
    component: turkuazCardService,
    meta: {
      title: "Turkuaz Card | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/money-transfer-service',
    name: 'money-transfer-service',
    component: moneyTransferService,
    meta: {
      title: "Money Transfer by Swift | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/immigration-problems-service',
    name: 'immigration-problems-service',
    component: immigrationProblemsService,
    meta: {
      title: "Immigration Problems | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/investment-consultancy-and-tax-law-service',
    name: 'investment-consultancy-and-tax-law-service',
    component: investmentConsultancyService,
    meta: {
      title: "Investment Consultancy & Tax Law | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/interpol-case-service',
    name: 'interpol-case-service',
    component: interpolCaseService,
    meta: {
      title: "Interpol Cases | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/real-estate-law-service',
    name: 'real-estate-law-service',
    component: realEstateLawService,
    meta: {
      title: "Real Estate Law | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/commerce-and-maritime-law-service',
    name: 'commerce-and-maritime-law-service',
    component: commerceAndMaritimeLawService,
    meta: {
      title: "Commerce & Maritime Law | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/family-law-service',
    name: 'family-law-service',
    component: familyLawService,
    meta: {
      title: "Family Law | Viya Legal Citizenship & Investment Services"
    }
  },
  {
    path: '/real-estate-purchase-and-brokerage-service',
    name: 'real-estate-purchase-and-brokerage-service',
    component: RealEstatePurchaseAndBrokerageService,
    meta: {
      title: "Real Estate Purchase & Brokerage | Viya Legal Citizenship & Investment Services"
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(
  (to, from, next) =>{
    if (to.matched.length < 1) {
      next(false);
      router.push('/404');
    }
    document.title = `${to.meta.title}`;
    next();
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
  

);

export default router
