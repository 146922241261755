<template>
    <div class="position-fixed" style="right:10px; bottom:40px; z-index:50;">
        <div class="row mb-1" v-if="false">
            <div class="col-8"></div>
            <div class="col-3 p-0"><div class="col-3 p-0"><a href="tel:+905424155315" class="call-button rounded-circle shadow-lg p-3 d-flex justify-content-center"><span class="align-self-center fs-4"><i class="fa-solid fa-phone"></i></span></a></div></div>
        </div>
        <div class="row m-0 d-flex justify-content-end pe-3 none-1024">
            <div class="col-7 d-flex pe-1">
                <div class="row p-2 align-self-center rounded bg-light fst-light shadow-lg ms-5" style="font-size:10px; width:250px;"><span>Need help? Chat with us</span></div>
            </div>
            <div class="col-2 p-0"><a href="https://wa.me/message/VLPMWUAJXBKBB1"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png" class="shadow-lg rounded-circle" style="width:65px; height:65px;" alt=""></a></div>
        </div>
    </div>
</template>

<style scoped>
    .call-button{
        background-color:#027bce;
        width: 70px;
        height: 70px;
        color:white;
        display: block;
        font-size:14px;
        text-align: center;
    }
    .call-button:hover{
        background-blend-mode:soft-light;
    }
</style>