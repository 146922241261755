<template>
    <router-link to="/en/properties/details" class="card mx-2 my-2 px-0 shadow" style="width: 310px; background-color: #F2F0F5;">
                    <img src="https://viyacitizenshipservices.com/application/files/8716/2425/9260/Swedish_Consulate_Istanbul.webp" alt="..." class="img-fluid ps-0">
                    <div class="card-body px-4">
                        <div class="row fw-bolder text-dark"><h3>Property name</h3></div>
                        <div class="row mb-4 text-warning fw-bold">
                            <div class="col-1"><i class="fa-solid fa-location-dot"></i></div>
                            <div class="col ps-1"><span>ATAŞEHİR/İSTANBUL</span></div>
                        </div>
                        <div class="row fw-light text-dark">
                            <span style="font-size: small;">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit .
                            </span>
                        </div>
                        <div class="row"><div class="w-100"><hr></div></div>
                        <div class="row">
                            <span class="d-flex justify-content-center text-info fw-bold">
                                <span class="mx-3"><i class="fa-solid fa-person-shelter"></i> 3+1</span>
                                <span class="mx-3"><i class="fa-solid fa-compass-drafting"></i> 969m2</span>
                                <span class="mx-3"><i class="fa-solid fa-building"></i></span>
                            </span>
                        </div>
                    </div>
     </router-link>
</template>

<style scoped>
.card{
    text-decoration: none;
}
</style>