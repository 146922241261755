<template>
                <div id="guide-card-right" class="guide-card-container">
                    <div  class="guide-card bg-light shadow-sm rounded border fs-14" >

                        <!-- <div v-if="false" class="row m-0 p-3">
                            <div class="col-3 p-0">
                                <div class="row"><img src="https://storage.viyalegal.com/website/viyalegal.com/images/mj.png" alt="" class="rounded-circle p-0" style="width:100%; height:80%;"></div>
                                <div class="row d-flex justify-content-around p-1" style="width:50px;">
                                    <img src="https://cdn.britannica.com/42/3842-004-F47B77BC/Flag-Russia.jpg" class=" p-0 m-0 border border-dark-blue" style="width:20px; height:15px; border-radius:3px;" alt="">
                                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png" class=" p-0 m-0 border border-dark-blue" style="width:20px; height:15px; border-radius:3px;" alt="">
                                </div>
                            </div>
                            <div class="col-9 ps-2">
                                <div class="row fw-bold"><span class="p-0">Mahri JEMSHIDOVA</span></div>
                                <div class="row"><div class="col-2 p-0 m-0"><i class="fa-solid fa-envelope p-0 m-0" style="width:4px;"></i></div><div class="col-9 p-0 m-0"><a class="p-0" href="mailto:mahri@viyalegal.com">mahri@viyalegal.com</a></div></div>
                                <div class="row"><div class="col-2 p-0 m-0"><i class="fa-solid fa-phone p-0 m-0" style="width:4px;"></i></div><div class="col-9 p-0 m-0"><a class="p-0" href="tel:+905424155315">+90 542 415 53 15</a></div></div>
                            </div> 
                        </div> -->

                        <div class="row m-0 p-3">
                            <div class="w-25 p-0">
                                <div class="row"><img src="https://storage.viyalegal.com/website/viyalegal.com/images/mj.png" alt="" class="rounded-circle p-0" style="width:100%; height:80%;"></div>
                            </div>
                            <div class="w-75 guide-card-body pe-0">
                                <div class="row fw-bold"><span class="p-0 guide-card-header">Mahri JEMSHIDOVA</span></div>
                                <div class="row d-flex justify-content-around m-0 p-0 fs-12 text-primary"><span class="p-0 guide-card-label" style="line-height:100%;">Русский / English</span></div>
                                <div class="w-100"><i class="fa-solid fa-envelope p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="mailto:mahri@viyalegal.com">mahri@viyalegal.com</a></div>
                                <div class="w-100"><i class="fa-solid fa-phone p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="tel:+905424155315">+90 542 415 53 15</a></div>
                            </div>
                        </div>

                        <div class="row m-0 p-3">
                            <div class="w-25 p-0">
                                <div class="row"><img src="https://storage.viyalegal.com/website/viyalegal.com/images/meb.png" alt="" class="rounded-circle p-0" style="width:100%; height:80%;"></div>
                            </div>
                            <div class="w-75 guide-card-body pe-0">
                                <div class="row fw-bold"><span class="p-0 guide-card-header">Mediha Esra BAŞER</span></div>
                                <div class="row d-flex justify-content-around m-0 p-0 fs-12 text-primary"><span class="p-0 guide-card-label" style="line-height:100%;">عربي / English</span></div>
                                <div class="w-100"><i class="fa-solid fa-envelope p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="mailto:mahri@viyalegal.com">esra@viyalegal.com</a></div>
                                <div class="w-100"><i class="fa-solid fa-phone p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="tel:+905465777817">+90 546 577 78 17</a></div>
                            </div> 
                        </div>

                        <div class="row m-0 p-3">
                            <div class="w-25 p-0">
                                <div class="row"><img src="https://storage.viyalegal.com/website/viyalegal.com/images/es.png" alt="" class="rounded-circle p-0" style="width:100%; height:80%;"></div>
                            </div>
                            <div class="w-75 guide-card-body pe-0">
                                <div class="row fw-bold"><span class="p-0 guide-card-header">Elnura SAGYNDYKOVA</span></div>
                                <div class="row d-flex justify-content-around m-0 p-0 fs-12 text-primary"><span class="p-0 guide-card-label" style="line-height:100%;">Русский / English</span></div>
                                <div class="w-100"><i class="fa-solid fa-envelope p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="mailto:mahri@viyalegal.com">elnura@viyalegal.com</a></div>
                                <div class="w-100"><i class="fa-solid fa-phone p-0 m-0" style="width:4px;"></i><a class="p-0 ps-3 guide-card-text" href="tel:+905417604116">+90 541 760 41 16</a></div>
                            </div> 
                        </div>

                    </div>
                </div>
</template>

<style scoped>
    .guide-card{
        right:30px;
        width: 260px;
    }
    
    .guide-card-body{
        padding-left:10px ;
    }
    
    @media screen and (max-width:1366px){
        .guide-card{
            right: 15px;
        }
        .guide-card-contanier{
            width: 200px;
        }
    }

    @media screen and (max-width:1250px){
        .guide-card{
            right:0px;
        }
    }

    @media screen and (max-width:1024px){
        .guide-card{
            width: 60%;
        }

        .guide-card-header{
            font-size: 22px;
        }

        .guide-card-label{
            font-size: 20px;
        }

        .guide-card-text{
            font-size: 22px;
        }

        .guide-card-body{
            padding-left:25px;
        }
    }

    @media screen and (max-width:960px){
        .guide-card{
            width:80%;
        }
    }

    @media screen and (max-width:512px) {
        .guide-card{
            width:80%;
            
        }
        
        .guide-card-header{
            font-size: 20px;
        }

        .guide-card-label{
            font-size: 18px;
        }

        .guide-card-text{
            font-size: 20px;
        }

        .guide-card-body{
            padding-left:25px;
        }
    }

    @media screen and (max-width:512px) {
        .guide-card-header{
            font-size:18px;
        }
        
        .guide-card-text{
            font-size: 16px;
        }
    }

</style>
