<template>
<div>
        <navbar-section-en></navbar-section-en>
    <div class="container-xxl p-0" style="max-width: 1920px; font-family: 'Source Sans Pro', sans-serif;" >

        <!-- CONTENT SECTION START -->
        <div class=" row text-dark">

            <div class="d-flex p-0 row justify-content-center">

                <!-- guide card left start-->
                <guide-card :class="{ 'margin-top-30' : windowTop>guideDistance, 'margin-top' : windowTop<guideDistance }"></guide-card>
                <!-- guide card left right-->

                        <img src="https://storage.viyalegal.com/website/viyalegal.com/images/residence-banner.png" alt="..." style="width:100%; object-fit:contain;" class="w-100 p-0 pt-0">
                <!--residency content start-->
                <div class="service-content-container d-flex justify-content-center">
                    <div class="text-dark">

                        <div class="row">
                            <!-- <div class="row text-start  px-0 text-dark"><span class="px-0" style="font-size: 42px;"><span class="fw-bold">Residency</span> Service</span></div> -->
                        </div>

                        
                        <div id="long-term-residence-permit"></div>
                        <div id="application"></div>
                        <p class="fw-bold fs-2 pt-4 text-center">{{lang.serviceResidency.title[0]}}</p>
                        <img v-if="false" src="https://storage.viyalegal.com/website/viyalegal.com/images/residence-card.png" style="object-fit: cover; " alt="" class="w-50">
                        <p>{{lang.serviceResidency.content[0]}}</p>
                        <br>

                        <p class="fw-bold fs-4">{{lang.serviceResidency.title[1]}}</p>
                        <p>{{lang.serviceResidency.content[1]}}</p>

                        <p class="fw-bold fs-5">{{lang.serviceResidency.title[2]}}</p>
                        <ul class="px-5" style="list-style-type:circle;">
                            <li>{{lang.serviceResidency.content[2]}}</li>
                            <li>{{lang.serviceResidency.content[3]}}</li>
                            <li>{{lang.serviceResidency.content[4]}}</li>
                            <li>{{lang.serviceResidency.content[5]}}</li>
                            <li>{{lang.serviceResidency.content[6]}}</li>
                            <li>{{lang.serviceResidency.content[7]}}</li>
                            <li>{{lang.serviceResidency.content[8]}}</li>
                            <li>{{lang.serviceResidency.content[9]}}</li>
                        </ul>
                        <br>
                        <p class="fw-bold fs-4">{{lang.serviceResidency.title[3]}}</p>
                        <div id="first-application"></div>
                        <p>{{lang.serviceResidency.content[10]}}</p>
                        <p>{{lang.serviceResidency.content[11]}}</p>
                        <div class="row my-4">
                            <div class="two">
                                <p class="fw-bold fs-5">{{lang.serviceResidency.title[4]}}</p>
                                <ul class="px-5" style="list-style-type:circle;">
                                    <li>{{lang.serviceResidency.content[12]}}</li>
                                    <li>{{lang.serviceResidency.content[13]}}</li>
                                    <li>{{lang.serviceResidency.content[14]}}</li>
                                    <li>{{lang.serviceResidency.content[15]}}</li>
                                    <li>{{lang.serviceResidency.content[16]}}</li>
                                    <li>{{lang.serviceResidency.content[17]}}</li>
                                    <li>{{lang.serviceResidency.content[18]}}</li>
                                </ul>
                            </div>
                            <div class="two">
                                <img class="h-100 w-100 p-0 m-0" style="object-fit:cover;" src="https://www.careeraddict.com/uploads/article/57993/write-job-application-letter.jpg" alt="">
                            </div>
                        </div>

                        <p class="fw-bold">{{lang.serviceResidency.title[5]}}</p>
                        <ul class="px-5" style="list-style-type: circle;">
                            <li>{{lang.serviceResidency.content[19]}}</li>
                            <li>{{lang.serviceResidency.content[20]}}</li>
                            <li>{{lang.serviceResidency.content[21]}}</li>
                            <li>{{lang.serviceResidency.content[22]}}</li>
                            <li>{{lang.serviceResidency.content[23]}}</li>
                        </ul>

                        <p class="fw-bold">{{lang.serviceResidency.title[6]}}</p>

                        <ul class="px-5" style="list-style-type:circle;">
                            <li>{{lang.serviceResidency.content[24]}}</li>
                            <li>{{lang.serviceResidency.content[25]}}</li>
                            <li>{{lang.serviceResidency.content[26]}}</li>
                            <li>{{lang.serviceResidency.content[27]}}</li>
                            <li>{{lang.serviceResidency.content[28]}}</li>
                        </ul>
                        <p class="fw-bold fs-5">{{lang.serviceResidency.title[7]}}</p>
                        
                        <p>{{lang.serviceResidency.content[29]}} <a class="fw-bold" href="https://e-ikamet.goc.gov.tr" target="_blank">(e-ikamet.goc.gov.tr)</a> {{lang.serviceResidency.content[30]}}</p>
                        <div class="row p-0 m-0">
                            <div class="two">
                                <ul class="px-5" style="list-style-type:circle;">
                                    <li>{{lang.serviceResidency.content[31]}}</li>
                                    <li>{{lang.serviceResidency.content[32]}}</li>
                                    <li>{{lang.serviceResidency.content[33]}}</li>
                                    <li>{{lang.serviceResidency.content[34]}}</li>
                                    <li>{{lang.serviceResidency.content[35]}}</li>
                                    <li>{{lang.serviceResidency.content[36]}}</li>
                                    <li>{{lang.serviceResidency.content[37]}}</li>
                                    <li>{{lang.serviceResidency.content[38]}}</li>
                                </ul>
                            </div>
                            <div class="two">
                                <img class="h-100 w-100 p-0 m-0" style="object-fit:cover;" src="https://www.storage.viyalegal.com/website/viyalegal.com/images/time-extension.png" alt="">
                            </div>
                        </div>

                    </div>
                </div>
                <!--residency content end-->

                <!--guide card right start-->
                <guide-contact-card :class="{ 'margin-top-30' : windowTop>50, 'margin-top' : windowTop<50 }"></guide-contact-card>
                <!--guide card right end-->

            </div>

        </div>
        <!-- CONTENT SECTION END -->
        
    </div>
    <footer-section-en></footer-section-en>
</div>
</template>

<script>
export default {
    data() {
        return {
            windowTop: 0,
            guideDistance: 50
        };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            console.log({ top: this.windowTop });
            }
        },
        computed:{
            lang(){
                return this.$store.state.lang;
            }
        }
    }
</script>