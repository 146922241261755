<template>
<div>
        <navbar-section-en></navbar-section-en>
    <div class="container-xxl p-0" style="max-width: 1920px; font-family: 'Source Sans Pro', sans-serif;" >

        <!-- CONTENT SECTION START -->
        <div class=" row text-dark">

            <div class="d-flex p-0 row justify-content-center">

                <!-- guide card left start-->
                <guide-card :class="{ 'margin-top-30' : windowTop>guideDistance, 'margin-top' : windowTop<guideDistance }"></guide-card>
                <!-- guide card left right-->

                        <img src="https://storage.viyalegal.com/website/viyalegal.com/images/company-establishment-banner.png" alt="..." style="width:100%; object-fit:contain;" class="w-100 p-0 pt-0">
                <!--company establishment  content start-->
                <div class="service-content-container d-flex justify-content-center">
                    <div class="text-dark">

                        <div class="row">
                                <div class="row text-center py-5"><span class=" fs-1"><span class="fw-bold">{{lang.serviceCompanyEstablishment.title[0]}}</span> {{lang.serviceCompanyEstablishment.title[1]}}</span></div>
                        </div>
                        <p class="fw-bold fs-2">{{lang.serviceCompanyEstablishment.title[2]}}</p>
                        <p>{{lang.serviceCompanyEstablishment.content[0]}}</p>
                        <p>{{lang.serviceCompanyEstablishment.content[1]}}</p>
                        <p>{{lang.serviceCompanyEstablishment.content[2]}}</p>
                        <p class="fw-bold">{{lang.serviceCompanyEstablishment.title[3]}}</p>
                        <p class="fw-bold">{{lang.serviceCompanyEstablishment.title[4]}}</p>
                        <ul style="list-style-type:circle;">
                            <li>{{lang.serviceCompanyEstablishment.content[3]}}</li>
                            <li>{{lang.serviceCompanyEstablishment.content[4]}}</li>
                        </ul>
                        
                        <p><br>{{lang.serviceCompanyEstablishment.content[5]}}</p>
                        <p class="fw-bold">{{lang.serviceCompanyEstablishment.title[5]}}</p>
                        <ul style="list-style-type:circle;">
                            <li>{{lang.serviceCompanyEstablishment.content[6]}}</li>
                            <li>{{lang.serviceCompanyEstablishment.content[7]}}</li>
                            <li>{{lang.serviceCompanyEstablishment.content[8]}}</li>
                        </ul>

                        <p>{{lang.serviceCompanyEstablishment.content[9]}}</p>

                        <p class="fw-bold fs-5">{{lang.serviceCompanyEstablishment.title[6]}}</p>
                        <p class="fw-bold">{{lang.serviceCompanyEstablishment.title[7]}}</p>
                        <p>{{lang.serviceCompanyEstablishment.content[10]}}</p>

                    </div>
                </div>
                <!--company establishment content end-->

                <!--guide card right start-->
                <guide-contact-card :class="{ 'margin-top-30' : windowTop>50, 'margin-top' : windowTop<50 }"></guide-contact-card>
                <!--guide card right end-->

            </div>

        </div>
        <!-- CONTENT SECTION END -->
        
    </div>
    <footer-section-en class="d-none" />
</div>
</template>

<style scoped>
    .guide-card{
        width: 250px;
        position: fixed;
    }

    @media screen and (max-width:1024px) {
    .guide-card{
        width: 100%;
        position: static;
    }
}
</style>


<script>
export default {
    data() {
        return {
            windowTop: 0,
            guideDistance: 50
        };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            console.log({ top: this.windowTop });
            }
        },
        computed:{
            lang(){
                return this.$store.state.lang;
            }
        }
    }
</script>
