<template>
  <div
    style="background-image:url('https://viyacitizenshipservices.com/application/themes/mediaclick/assets/img/19.jpg'); background-attachment:cover; background-repeat:no-repeat; background-size:cover;"
    class="bg-dark-blue row text-center p-4 text-light"
  >
    <p class="fs-1 mb-3">
      <span class="fw-bold">{{lang.component.viyaByNumbers.title[0]}}</span>{{lang.component.viyaByNumbers.title[1]}}
    </p>
    <div class="row d-flex justify-content-center">
      <div style="width:300px;">
        <p class="fs-1 fw-bold">{{lang.component.viyaByNumbers.content[0]}}</p>
        <p>{{lang.component.viyaByNumbers.title[2]}}</p>
      </div>

      <div style="width:300px;">
        <p class="fs-1 fw-bold">{{lang.component.viyaByNumbers.content[1]}}</p>
        <p>{{lang.component.viyaByNumbers.title[3]}}</p>
      </div>

      <div style="width:300px;">
        <p class="fs-1 fw-bold">{{lang.component.viyaByNumbers.content[2]}}</p>
        <p>{{lang.component.viyaByNumbers.title[4]}}</p>
      </div>

      <div style="width:300px;">
        <p class="fs-1 fw-bold">{{lang.component.viyaByNumbers.content[3]}}</p>
        <p>{{lang.component.viyaByNumbers.title[5]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>