<template>
  <!--navbar section start-->
  <div class="row">
    <div class="row bg-dark-blue text-light fs-4" style="font-family: 'The Nautigal';">
      <span>since<span class="fst-italic">1996</span><span class="fs-10 px-1" style="font-family: 'Gotham Pro', sans-serif;" >| Citizenship | Residency | Migration</span> <span class="text-end"></span>for a new life </span>
    </div>
    <nav id="navbar_top" ref="navbar_top" class="navbar navbar-expand-xl navbar-light bg-light shadow-sm">
      <div class="container-fluid" style="max-width:1920px;">
        
        <!-- LOGO-BRAND START -->
        <router-link to="/"><a class="navbar-brand text-dark-blue d-flex" href="/"><img src="https://storage.viyalegal.com/website/viyalegal.com/images/black-logo.png" style="width:50px; height:auto;" /></a></router-link>
        <router-link to="/"><a class="navbar-brand text-dark-blue d-flex" href="/"><span class="px-2">VIYA LEGAL</span><span class="fs-10 navbar-brand-text">Law & Citizenship<br />Services</span></a></router-link>
        <!--LOGO BRAND END -->

        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"><span class="navbar-toggler-icon"></span></button>

          <!-- offcanvas START -->
          <div class="custom-offcanvas-top offcanvas offcanvas-top h-100 bg-dark-blue" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header bg-dark-blue d-flex justify-content-between">
              <div class="w-100 d-flex justify-content-center align-items-center">
                <router-link to="/"><a class="navbar-brand text-dark-blue d-flex" href="/"><img src="../assets/images/light-logo.png" style="width:50px; height:auto;" /></a></router-link>
                <router-link to="/"><a class="navbar-brand text-light d-flex  " href="/"><span class="px-2">VIYA LEGAL</span><span class="fs-10 navbar-brand-text">Law & Citizenship<br />Services</span></a></router-link>
              </div>

              <button type="button" class="btn bg-transparent text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"><span class="fa-solid fa-xmark text-light fs-1 "></span></button>
            </div>
            <div class="offcanvas-body h-100 d-flex justify-content-center ">
              <div class="w-100 d-flex flex-d-row">
                <navs-section></navs-section>
              </div>
            </div>
          </div>
          <!-- offcanvas END -->

        <div class="collapse navbar-collapse text-center justify-content-center" id="navbarNavAltMarkup">
          
          <!-- Navbar-content START -->
            <navs-section></navs-section>
          <!-- Navbar-content  END-->




          <!-- Navbar-end START -->
          <div class="navbar-end  w-50 d-flex justify-content-end">

            <!-- Change Language START -->
              <div v-if="true" class="dropdown">
                <button style="padding-top:8px; padding-bottom:8px;" class="btn btn-light dropdown-toggle rounded-0 bg-transparent border-0 text-dark-blue" type="button" id="chooseLanguageDropdown" data-bs-toggle="dropdown" aria-expanded="false" >{{currentLanguage}}</button>
                <ul class="dropdown-menu" style="width:100px;" aria-labelledby="chooseLanguageDropdown">
                  <li class="d-flex" style="width:100px;">
                    <button
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('en')"
                    >English</button>
                  </li>
                  <li class="d-flex" style="width:100px;">
                    <button
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('ru')"
                    >Russian</button>
                  </li>
                  <li class="d-flex" style="width:100px;">
                    <button
                      class="w-100 btn btn-light bg-transparent text-dark-blue border-0"
                      @click="changeLanguage('ar')"
                    >Arabic</button>
                  </li>
                </ul>
              </div>
            <!-- Change Language END -->
            <!-- PHONE SECTION START -->
              <a href="tel:+905448392408" class="w-100  nav-link active px-0 text-center text-success bg-primar rounded-pill d-flex justify-content-center row" target="_blank" >
                <span class="align-self-center justify-content-end text-dark-blue row fw-700">
                  <span class="col-2" style="width:30px;height:30px;">
                    <span class="align-self-center fs-4">
                      <i class="shaking fa-solid fa-phone p-0 m-0"></i>
                    </span>
                  </span>
                  <span
                    class="col-sm-7 fs-14 col-md-10 py-1 col-6 fs-5-1400 text-start"
                  >{{ lang.navbar.clickToCallNow }}</span>
                </span>
              </a>
            <!-- PHONE SECTION END -->
          
        </div>
         <!-- Navbar-end END -->


        </div>
      </div>
    </nav>
  </div>
  <!--navbar section end-->
</template>

<style scoped>
    .fs-16{
        font-size: 16px;
    }
    .fs-14{
        font-size: 14px;
    }
    .fs-12{
        font-size: 12px;
    }

    @media screen and (min-width:1025px) {
      .custom-offcanvas-top{
        display: none;
      }
    }
</style>

<script>
import navs from './navs.vue'
export default {
    data:function(){
        return{
            currentLanguage:'EN'
        }
    },
    
    mounted(){
        this.navbarFlexibility();
    },
    methods:{
        changeLanguage(value){
            this.$store.commit("changeLanguage", value);
            if (value =="en"){
                this.currentLanguage = "EN";
            }
            else if (value == "ru"){
                this.currentLanguage = "RU";
            }
            else if(value == "ar"){
                this.currentLanguage = "AR";
            }
        },
        navbarFlexibility: function(){
            window.addEventListener('scroll', 
            function() {
                if (window.scrollY > 50) {
                    document.getElementById('navbar_top').classList.add('fixed-top');
                    // add padding top to show content behind navbar
                    var navbar_height;
                    navbar_height = document.querySelector('.navbar').offsetHeight;
                    document.body.style.paddingTop = navbar_height + 'px';
                    // document.getElementById('navbar_top').classList.remove("bg-light");
                    // document.getElementById('navbar_top').classList.add("bg-dark-blue");

                }
                
                else {
                    document.getElementById('navbar_top').classList.remove('fixed-top');
                    // document.getElementById('navbar_top').classList.remove("bg-dark-blue");
                    // document.getElementById('navbar_top').classList.add("bg-light");
                    // remove padding top from body
                    document.body.style.paddingTop = '0';
                } 

            });
        }
    },
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    },
    components:{
      'navs-section' : navs
    }
}
</script>