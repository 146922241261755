<template>
    <div class="row d-flex justify-content-center py-3" style="background-image: url(https://metropolitan.realestate/wp-content/themes/framework/assets/images/dotbg.png);">
        <div class="row text-center m-0 px-0 py-4  d-flex fst-italic"><span class="fs-2 text-dark-blue "><side-line class="w-25" /> WHY <span class="fw-bold">VIYA</span> <side-line class="w-25" style="" /></span></div>
        
        <div class="row d-flex justify-content-center">
            
            <div class="card m-3" style="width:200px">
            <div class="card-body">
                <div class="row text-center fs-1 text-dark-blue"><span><i class="fa-solid fa-language" /></span></div>
                <div class="row text-center fw-bold"><span>15+</span></div>
                <div class="row text-center fst-italic"><span>Language</span></div>
            </div>
        </div>
        <div class="card m-3" style="width:200px">
            <div class="card-body">
                <div class="row text-center fs-1 text-dark-blue"><span><i class="fa-solid fa-building"></i></span></div>
                <div class="row text-center fw-bold"><span>1000+</span></div>
                <div class="row text-center fst-italic"><span>Properties</span></div>
            </div>
        </div>
        <div class="card m-3" style="width:200px">
            <div class="card-body">
                <div class="row text-center fs-1 text-dark-blue"><span><i class="fa-solid fa-calendar-days"></i></span></div>
                <div class="row text-center fw-bold"><span>26+</span></div>
                <div class="row text-center fst-italic"><span>Years</span></div>
            </div>
        </div>
        <div class="card m-3" style="width:200px">
            <div class="card-body">
                <div class="row text-center fs-1 text-dark-blue"><span><i class="fa-solid fa-face-smile"></i></span></div>
                <div class="row text-center fw-bold"><span>800+</span></div>
                <div class="row text-center fst-italic"><span>Happy Client</span></div>
            </div>
        </div>
        <div class="card m-3" style="width:200px">
            <div class="card-body">
                <div class="row text-center fs-1 text-dark-blue"><span><i class="fa-solid fa-industry"></i></span></div>
                <div class="row text-center fw-bold"><span>70+</span></div>
                <div class="row text-center fst-italic"><span>Factory Investment</span></div>
            </div>
        </div>

        </div>
    
    </div>
</template>