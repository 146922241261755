<template>
  <navbar-section></navbar-section>
  <Transition name="fade">
    <router-view v-if="routerVisibility" />
  </Transition>
</template>

<style>
 .fade-enter-from{
    opacity: 0;
 }
 .fade-enter-to{
    opacity: 1;
 }
 .fade-enter-active{
    transition: all 0.5s ease;
 }
 .fade-leave-from{
  opacity: 1;
 }
 .fade-leave-to{
    opacity: 0;
 }
 .fade-leave-active{
  transition: all 0.5s ease;
 }
</style>

<script>
import NavbarSection from './components/navbar-section'

export default {
  data(){
    return{
      routerVisibility: true
    }
},
  components:{
    'navbar-section': NavbarSection
  },
  computed:{
    lang(){
      return this.$store.state.lang;
    }
  },
  methods:{
    forceRerender() {
      this.routerVisibility = false;
      this.$nextTick().then(() => {
        this.routerVisibility = true;
      });
    }
  },
  watch:{
    lang(){
      this.forceRerender();
    },
    '$route': function(to, from) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
    
}
</script>