<template>
    <!--consulting section start-->
        <div v-if="false" class="row m-0">
            <div class="container-fluid p-5" style="background-image: url('https://static.onecms.io/wp-content/uploads/sites/28/2021/07/30/istanbul-turkey-ISTANBULTG0721.jpg'); background-attachment: fixed; background-size: cover;">
                <div class="row d-flex justify-content-end">
                    <div class=" consultation-container">
                        <div class="container-fluid bg-light p-5 shadow-lg rounded">
                            <div class="row text-center fst-italic">
                                <h4 class="m-0"><side-line /> GET A FREE <span class="fw-normal">CONSULTATION</span> <side-line /></h4><br>
                                <span class=" text-dark">Let us direct you to an expert shortly</span>
                            </div>
                            <div class="row mt-5">
                                <form action="#" method="post">
                                    <div class="row mb-3">
                                        <div class="col"><input type="text" class="form-control" placeholder="Name"></div>
                                        <div class="col"><input type="text" class="form-control" placeholder="Surname"></div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col"><input type="text" class="form-control" placeholder="Phone Number"></div>
                                        <div class="col"><input type="text" class="form-control" placeholder="E-mail"></div>
                                    </div>
                                    <div class="row mb-3"><button class="btn btn-primary">Send</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--consulting section end-->
</template>

<style scoped>
    .consultation-container{
        width:50%;
    }
    
    @media screen and (max-width:1280px) {
        .consultation-container{
            width: 100%;
        }
    }
</style>