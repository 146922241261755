<template>
                <div class="guide-card-container none-1024">
                    <div id="guide-card-2" class="guide-card bg-light p-3 pt-3 shadow-sm rounded border fs-14">
                        <!-- <ul class="p-0">
                            <li class="fw-bold pb-2 fs-5 text-dark-blue"><i class="fa-solid fa-house-chimney"></i> Residency Permit</li>
                            <ul class="p-0 ps-1">
                                <li><a href="#long-term-residence-permit" class="rmv-underline text-dark">1. Long term resident permit</router-link></li>
                                <ul>
                                    <li><a href="#application" class="rmv-underline text-dark">1.1 Application</router-link></li>
                                </ul>
                                <li><a href="#short-term-residence-permit" class="rmv-underline text-dark">2. Short term resident permit</router-link></li>
                                <ul>
                                    <li><a href="#first-application" class="rmv-underline text-dark">2.1 First Application</router-link></li>
                                    <li><a href="#extension-application" class="rmv-underline text-dark">2.2 Extension Apllication</router-link></li>
                                </ul>
                            </ul>
                        </ul>

                        <a class="text-center fs-5" data-bs-toggle="collapse" href="#other-services-collapse" role="button" aria-expanded="false" aria-controls="other-services-collapse">
                            <i class="fa-solid fa-caret-down"></i><span class="ps-2 fw-bold">Other Services</span>
                        </router-link> -->
                        <div id="other-services-collapse">
                            <ul class="p-0">
                                    <li><router-link to="/services" class="fw-bold py-2 fs-6">{{lang.component.guideCard.title[0]}}</router-link></li>
                                    <li>-<router-link to="/citizenship-service">{{lang.component.guideCard.title[1]}}</router-link></li>
                                    <li>-<router-link to="/residency-service">{{lang.component.guideCard.title[2]}}</router-link></li>
                                    <li>-<router-link to="/company-establishment-service">{{lang.component.guideCard.title[3]}}</router-link></li>
                                    <li>-<router-link to="/turkuaz-card-service">{{lang.component.guideCard.title[4]}}</router-link></li>
                                    <li>-<router-link to="/money-transfer-service">{{lang.component.guideCard.title[5]}}</router-link></li>
                                    <li>-<router-link to="/immigration-problems-service">{{lang.component.guideCard.title[6]}}</router-link></li>
                                    <li>-<router-link to="/investment-consultancy-and-tax-law-service">{{lang.component.guideCard.title[7]}}</router-link></li>
                                    <li>-<router-link to="/interpol-case-service">{{lang.component.guideCard.title[8]}}</router-link></li>
                                    <li>-<router-link to="/real-estate-law-service">{{lang.component.guideCard.title[9]}}</router-link></li>
                                    <li>-<router-link to="/commerce-and-maritime-law-service">{{lang.component.guideCard.title[10]}}</router-link></li>
                                    <li>-<router-link to="/family-law-service">{{lang.component.guideCard.title[11]}}</router-link></li>
                                    <li>-<router-link to="/real-estate-purchase-and-brokerage-service">{{lang.component.guideCard.title[12]}}</router-link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
</template>

<style scoped>
    .guide-card{
        left:30px;
    }

    @media screen and (max-width:1366px){
        .guide-card{
            left:15px;
        }
    }

</style>

<script>
export default {
    data() {
    return {
      windowTop: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      console.log({ top: this.windowTop });
    }
  },
  computed:{
    lang(){
        return this.$store.state.lang;
    }
  }
}
</script>