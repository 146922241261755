import { createStore } from 'vuex'
import enLanguageJSON from '../language/en.json'
import ruLanguageJSON from '../language/ru.json'
import arLanguageJSON from '../language/ar.json'

export default createStore({
  state: {
    homeModalTriggeredStatus:false,
    lang:enLanguageJSON,
    language: "en"
  },
  getters: {
  },
  mutations: {
    changeLanguage(state, value){
      if(value == "en"){
        state.lang = enLanguageJSON;
        state.language = "en";
      }
      else if( value == "ru"){
        state.lang = ruLanguageJSON;
        state.language = "ru";
      }
      else if( value == "ar"){
        state.lang = arLanguageJSON;
        state.language = "ar";
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
