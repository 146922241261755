import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/bootstrap/js/bootstrap.js'
import './assets/style/bootstrap/css/bootstrap.css'
import '../src/assets/style/main.css'
// import i18n from './i18n'

// import { createI18n } from 'vue-i18n/index'

//comps
import footerSectionEn from './components/footer-section-en'
import consultingSectionEn from './components/consulting-section-en'
import sideLine from './components/side-line'
import propertyCard from './components/property-card'
import wpButton from './components/wp-button'
import whyViyaSection from './components/why-viya-section'
import propertyShowcaseSection from './components/property-showcase-section'
import staffSection from './components/staff-section'
import becomePartnerSection from './components/become-partner-section'
import checkPropertiesSection from './components/check-properties-section'
import checkServicesSection from './components/check-services-section'
import clientFeedbacksSection from './components/client-feedbacks-section'
import faqSectionEn from './components/faq-section-en'
import serviceCard from './components/service-card'
import servicesSectionEn from './components/services-section-en'
import feedbackCard from './components/feedback-card'
import whyUsCard from './components/why-us-card'
import cbiOptionsCard from './components/cbi-options-card'
import whyChooseViya from './components/why-choose-viya'
import whyUsSection from './components/why-us-section'
import guideContactCard from './components/guide-contact-card'
import guideCard from './components/guide-card'
import viyaWithNumbers from './components/viya-with-numbers'

// createI18n(App)
createApp(App).use(i18n).use(store).use(router)

    .component("footer-section-en", footerSectionEn)
    .component("consulting-section-en", consultingSectionEn)
    .component("side-line", sideLine)
    .component("property-card", propertyCard)
    .component("wp-button", wpButton)
    .component("why-viya-section", whyViyaSection)
    .component("property-showcase-section", propertyShowcaseSection)
    .component("staff-section", staffSection)
    .component("become-partner-section", becomePartnerSection)
    .component("check-properties-section", checkPropertiesSection)
    .component("check-services-section", checkServicesSection)
    .component("client-feedbacks-section", clientFeedbacksSection)
    .component("faq-section-en", faqSectionEn)
    .component("service-card", serviceCard)
    .component("services-section-en", servicesSectionEn)
    .component("feedback-card", feedbackCard)
    .component("why-us-card", whyUsCard)
    .component("cbi-options-card", cbiOptionsCard)
    .component("why-choose-viya", whyChooseViya)
    .component("why-us-section", whyUsSection)
    .component("guide-contact-card", guideContactCard)
    .component("guide-card", guideCard)
    .component("viya-with-numbers", viyaWithNumbers)

    .mount('#app')
