<template>
            <router-link tag="a" v-bind:to="link" class="card p-0 shadow m-2 rounded rmv-underline" style="width:300px;">
                    <div class="row"><img v-bind:src="image" alt="..." class="img-fluid p-0 rounded-top" style="width:600px; height:100px; object-fit:cover;"></div>
                <div class="card-body m-0">
                    <div class="row fs-4 mb-3"><a v-bind:href="link" class="rmv-underline text-dark-blue">{{title}}</a></div>
                    <div class="row fw-light mb-3 text-dark"><span>{{content}}</span></div>
                    <div class="row"><button class="btn text-end text-primary fst-italic">See Details</button></div>
                </div>
            </router-link>
</template>

<script>
export default {
    props:['image','title', 'content', 'link']
}
</script>