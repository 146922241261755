<template>
  <div class="w-100">
    <!-- VIDEO SECTION START -->
    <div
      class="row shadow position-relative banner_video"
      style="height:840px; overflow-y:hidden; overflow-x:hidden;"
    >
      <!-- cover -->
      <div
        class="position-absolute top-0 left-0 w-100 h-100 bg-dark"
        style="z-index:5; opacity:0.7;"
      ></div>
      <!-- video container -->
      <div class="d-flex justify-content-center position-absolute top- left-0 w-100 h-100 p-0">
        <video
          class="w-100 position-absolute"
          style="min-width:1600px; height:80%; min-height:1800px; top:-480px;"
          controls
          muted
          autoplay
          loop
        >
          <source src="../assets/media/video.mp4" type="video/mp4" />
        </video>
        <!-- <iframe class="w-100 position-absolute" style="min-width:1600px; min-height:1800px; top:-480px;" allow="autoplay;" src="https://www.youtube.com/embed/eV6lTEY95yY?playlist=eV6lTEY95yY&autoplay=1&controls=0&disablekb=1&fs=0&iv_load_policy=3&loop=1&modestbranding=1&playsinline=1&rel=0&showinfo=0&mute=1&enablejsapi=1&origin=https%3A%2F%2Fwww.viyalegal.com&widgetid=1" frameborder="0" loop></iframe> -->
      </div>
    </div>
    <!-- VIDEO SECTION END -->
    <div class="row position-fixed w-100 d-flex justify-content-center text-light" style="top:20%; z-index:7;">
      <div class="row text-center mt-3 w-70 d-flex">
        <span style="font-size:220px;" class="fw-bold p-0 title-404 w-50">404</span>
        <span class="fs-1 text-center p-0 w-50">
            <p class="fw-bold">UH OH! You're lost</p>
            <p class="fs-5">The page you are looking for removed or does not exist.</p>
            <!-- <p class="fs-6 text-start "><a class="text-light" href="/">Return to homepage</a></p> -->
            <p class="fs-6"><router-link to="/"><button class="w-50 bg-light fw-bold text-dark-blue border-0 py-2">Return to homepage</button></router-link></p>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title-404{
    font-size: 240px;
}

@media screen and (max-width:768px) {
.title-404{
    font-size: 100px;
}
}
</style>