<template>
<div>
    <div class="container-xxl p-0" style="max-width: 1920px;">
                        <img src="../../src/assets/images/about-banner.png" alt="..." style="width:100%; object-fit:contain;" class="w-100 p-0 pt-0">
        
        <!--about us section start-->
        <div class="row m-0 text-dark" style="font-family: 'Montserrat', sans-serif;">
            <div class="container rpx-5" style="width: 90%;">
                <div class="row text-center p-0 m-0 mb-5 mt-5 fst-italic"><h1>{{lang.aboutPage.title.general[0]}} </h1></div>
                <div class="row rpx-5 m-0 pb-0">
                    <div v-if="true" class="row m-0 p-0 mb-5">

                        <div class="two">
                            <p class="fw-bold fs-5">{{lang.aboutPage.title.presentation[0]}}</p>
                            <p>{{lang.aboutPage.content.presentation[0]}}</p>
                            <p>{{lang.aboutPage.content.presentation[1]}}</p>
                            <p>{{lang.aboutPage.content.presentation[2]}}</p>
                            </div>
                        
                        <div class="two ps-3 pe-3">
                            <img src="../../src/assets/images/istanbul-portrait.jpg" alt="..." class="img-fluid rounded w-100 mt-5" style="height:500px; object-fit:cover;">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--about us section end-->

        <why-choose-viya></why-choose-viya>

        <!--memberships section start-->
        <div class="row m-0 rp-5 d-flex justify-content-center" >
            <div class="card m-2 our-memberships-card">
                <div class="card-body"> 
                    <div class="row"><img :src="lang.component.ourMemberships.image[0]" alt="..." class="img-fluid mx-auto" style="width: 130px;"></div>
                    <div class="row m-0 text-center">
                        <div class="row m-0 fs-5"><p class="m-0">{{lang.component.ourMemberships.title[0]}}</p></div>
                        <div class="row m-0 "><p class="m-0">{{lang.component.ourMemberships.content[0]}}</p></div>
                        <div class="row m-0 fw-bold"><p class="m-0">{{lang.component.ourMemberships.id[0]}}</p></div>
                    </div>
                </div>
            </div>

            <div class="card m-2 our-memberships-card">
                <div class="card-body"> 
                    <div class="row"><img :src="lang.component.ourMemberships.image[1]" alt="..." class="img-fluid mx-auto" style="width: 130px;"></div>
                    <div class="row m-0 text-center">
                        <div class="row m-0 fs-5"><p class="m-0">{{lang.component.ourMemberships.title[1]}}</p></div>
                        <div class="row m-0 "><p class="m-0">{{lang.component.ourMemberships.content[1]}}</p></div>
                        <div class="row m-0 fw-bold"><p class="m-0">{{lang.component.ourMemberships.id[1]}}</p></div>
                    </div>
                </div>
            </div>

            <div class="card m-2 our-memberships-card">
                <div class="card-body"> 
                    <div class="row"><img :src="lang.component.ourMemberships.image[2]" alt="..." class="img-fluid mx-auto" style="width: 130px;"></div>
                    <div class="row m-0 text-center">
                        <div class="row m-0 fs-5"><p class="m-0">{{lang.component.ourMemberships.title[2]}}</p></div>
                        <div class="row m-0 "><p class="m-0">{{lang.component.ourMemberships.content[2]}}</p></div>
                        <div class="row m-0 fw-bold"><p class="m-0">{{lang.component.ourMemberships.id[2]}}</p></div>
                    </div>
                </div>
            </div>

            <div class="card m-2 our-memberships-card">
                <div class="card-body"> 
                    <div class="row"><img :src="lang.component.ourMemberships.image[3]" alt="..." class="img-fluid mx-auto" style="width: 130px;"></div>
                    <div class="row m-0 text-center">
                        <div class="row m-0 fs-5"><p class="m-0">{{lang.component.ourMemberships.title[3]}}</p></div>
                        <div class="row m-0 "><p class="m-0">{{lang.component.ourMemberships.content[3]}}</p></div>
                        <div class="row m-0 fw-bold"><p class="m-0">{{lang.component.ourMemberships.id[3]}}</p></div>
                    </div>
                </div>
            </div>

            <div class="card m-2 our-memberships-card">
                <div class="card-body"> 
                    <div class="row"><img :src="lang.component.ourMemberships.image[4]" alt="..." class="img-fluid mx-auto" style="width: 130px;"></div>
                    <div class="row m-0 text-center">
                        <div class="row m-0 fs-5"><p class="m-0">{{lang.component.ourMemberships.title[4]}}</p></div>
                        <div class="row m-0 "><p class="m-0">{{lang.component.ourMemberships.content[4]}}</p></div>
                        <div class="row m-0 fw-bold"><p class="m-0">{{lang.component.ourMemberships.id[4]}}</p></div>
                    </div>
                </div>
            </div>
        </div>
        <!--memberships section end-->

        <!-- viya with numbers section start -->
        <viya-with-numbers></viya-with-numbers>
        <!-- viya with numbers section end -->

        <!--numbers section start-->
        <div v-if="false" class="row m-0 mb-5 p-3 d-flex justify-content-center" >
            <div class="card shadow px-5 py-3 m-1" style="min-width:460px; max-width: 35%;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-7">
                            <div class="row fw-bold fs-1">
                                <p>120.000+</p>
                            </div>
                            <div class="row fw-bold">
                                <p>Properties</p>
                            </div>
                            <div class="row fw-light">
                                <p>in İstanbul and other cities.</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <img src="/src/images/icon-property.svg" alt="..." class="img-fluid" style="width: 100%;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="card shadow px-5 py-3 m-1" style="min-width:460px; max-width: 35%;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-7">
                            <div class="row fw-bold fs-1">
                                <p>500+</p>
                            </div>
                            <div class="row fw-bold">
                                <p>Customers</p>
                            </div>
                            <div class="row fw-light">
                                <p>have worked with Viya.</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <img src="/src/images/icon-customer.svg" alt="..." class="img-fluid" style="width: 100%;">
                        </div>
                    </div>
                </div>
            </div>

    7u6j7u6jhl
            <div class="card shadow px-5 py-3 m-1" style="min-width:460px; max-width: 35%;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-7">
                            <div class="row fw-bold fs-1">
                                <p>25+</p>
                            </div>
                            <div class="row fw-bold">
                                <p>Years</p>
                            </div>
                            <div class="row fw-light">
                                <p>of experience.</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <img src="/src/images/icon-calendar.svg" alt="..." class="img-fluid" style="width: 100%;">
                        </div>
                    </div>
                </div>
            </div>

            <div class="card shadow px-5 py-3 m-1" style="min-width:460px; max-width: 35%;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-7">
                            <div class="row fw-bold fs-1">
                                <p>150</p>
                            </div>
                            <div class="row fw-bold">
                                <p>top performing agents</p>
                            </div>
                            <div class="row fw-light">
                                <p>who specialize in your target area</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <img src="/src/images/icon-agents.svg" alt="..." class="img-fluid" style="width: 100%;">
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!--numbers section end-->

        <staff-section></staff-section>

        <consulting-section-en class="m-5"/>

        <!--become partner start-->
        <become-partner-section v-if="false" class="m-5"/>
        <!--become partner end-->

        <!--check properties start-->
        <check-properties-section v-if="false" class="m-5"/>
        <!--check properties end-->

        <!--check services start-->
        <check-services-section v-if="false" class="m-5"/>
        <!--check services end-->
    </div>
    <footer-section-en></footer-section-en>
</div>
</template>

<script>
export default {
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>