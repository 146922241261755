<template>
    <div class="card d-flex p-2 text-center mx-1 bg-dark-blue text-light border-0" style="width:250px; height:auto;">
        <div class="w-100 d-flex justify-content-center"><span class=" fs-3"><i :class="icon + ' pb-4'" style="font-size:50px;" /></span></div>
        <div class="w-100 my-2"><div class="card-title "><span class="fw-bold fs-14">{{title}}</span></div></div>
        <div class="w-100 ">
            <div class="card-body fs-12">
                {{content}}
            </div>
        </div>
        <div class="w-100 position-relative">
            <button class="btn border-light border-3 rounded-0 fs-12 btn-hover">GET MORE INFORMATION</button>
        </div>
    </div>
</template>

<style scoped>
    .btn-hover{
        color:white;
    }
    .btn-hover:hover{
        color:#1b2e4d;
        background-color: white;
    }
</style>

<script>
export default {
    props:['icon','title','content']
}
</script>