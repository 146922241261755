<template>

<div class="row p-5">      
    <div class="row d-flex justify-content-center">
        
        <service-card v-if="true"
        image="https://www.akkaslaw.com/wp-content/uploads/2018/11/Turkish-Citizenship-Lawyers.jpg"
        link="/en/citizenship" 
        title="Citizenship" 
        content="The Right to Acquire Turkish Citizenship by Purchasing Real Estate. People of foreign nationality are given the right to acquire Turkish citizenship through exceptional procedures, in case they purchase real estate worth $400,000 or more."
        />

        <service-card v-if="true"
        image="https://images.unsplash.com/photo-1606340670914-f2c255d90ee0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"
        link="/en/residency" 
        title="Residency" 
        content="Türkiye’de, kalış sürelerini vizelerinde belirtilen süreyi aşacak şekilde uzatmak isteyen, vize muafiyetine ihtiyaç duyan veya doksan günden fazla kalmayı düşünen yabancıların oturum almaları zorunludur." 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1462206092226-f46025ffe607?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80"
        link="/en/company-establishment-and-management" 
        title="Company Establishment & Management" 
        content="​​​​​​​​​​​​​​​​​Türkiye’nin Doğrudan Yabancı Yatırımlar (DYY) Kanunu, eşit muamele ilkesine dayalı olup uluslararası yatırımcıların yerel yatırımcılar ile aynı hak ve yükümlülüklere sahip olmasına olanak tanımaktadır. Şirket kurma ve hisse devri koşulları, yerel yatırımcılara uygulananlar ile aynıdır. Buna göre, uluslararası yatırımcılar" 
        />
        
        <service-card v-if="false"
        image="https://turkpermit.com.tr/images/ItemPhotos/hfo4CUmrLYECTjQfgaUkoUWZlGOirPVkRQfm3xaO.png"
        link="/en/turkuaz-card" 
        title="Turkuaz Card" 
        content="Turkuaz Kart danışmanlık hizmetimiz sayesinde çok ekonomik ücretlerle Turkuaz Kart başvurunuzu yapıyoruz. Başvurunuzun onaylanması ve Turkuaz Kart programına alınmanız durumunda üç yıllık geçiş sürecinin yönetimini de biz gerçekleştiriyoruz. Kendi dilinizde ve Türkçe yazılmış bir sözleşme ile bizden alacağınız hizmeti garanti altına almanızı sağlıyoruz." 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1591033594798-33227a05780d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1059&q=80"
        link="/en/money-and-swift-transfer-banking-operations" 
        title="Money & Swift Transfer - Banking Operations" 
        content="Yabancılara Türkiye’den banka hesabı açılması ve bankacılık hizmetleri konusunda gerekli yasal hizmetlerin tamamı Viya Hukuk ve Vatandaşlık tarafından sağlanmaktadır." 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1564041549956-3ad6fa9f5517?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        link="/en/immigration-problems"
        title="Immigration Problems - Deportation, Visa Cancellation" 
        content="Yabancıların sıklıkla yaşadığı bu tür hukuki sorunlara karşı yasal başvurular yapılmakta ve gerek idare gerekse mahkemelerden alınan kararlar ile bu sorunlar Viya’nın deneyimli hukuk ekibi tarafından çözülmektedir." 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        link="/en/investment-consultancy-and-tax-law"
        title="Investment Consultancy & Tax Law" 
        content="a" 
        />

        <service-card v-if="false"
        image="https://i4.hurimg.com/i/hurriyet/75/750x422/609ee2f2c9de3d340cbbe9c9.jpg"
        link="/en/interpol-cases"
        title="Interpol Cases" 
        content="a" 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1592595896551-12b371d546d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        link="/en/real-estate-law"
        title="Real Estate Law" 
        content="a" 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1617952739858-28043cecdae3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        link="/en/commerce-law-and-maritime-commercial-law"
        title="Commerce Law - Maritime Commercial Law" 
        content="a" 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/photo-1581579186913-45ac3e6efe93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        link="/en/family-law"
        title="Family Law" 
        content="a" 
        />

        <service-card v-if="false"
        image="https://images.unsplash.com/flagged/photo-1564767609342-620cb19b2357?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1073&q=80"
        link="/en/real-estate-purchase-and-brokerage-services"
        title="Real Estate Purchase & Brokerage Services " 
        content="a" 
        />

    </div>
</div>
</template>