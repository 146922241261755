<template>
    <div class="row">
            <div class="row">
                <div class="row p-4 d-flex justify-content-center">

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>
                    
                    <property-card></property-card>

                    <property-card></property-card>

                    <property-card></property-card>
                           
                </div>
            </div>
            <div class="row">
                <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                    <a class="page-link" href="" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                    <a class="page-link" href="" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
                </nav>
            </div>
            <div class="row text-center mb-4"><router-link to="/en/properties"><span>See all properties</span></router-link></div>
        </div>
</template>