<template>

        <div class="card m-1 p-2 why-us-card">
            <div class="row d-flex justify-content-start">
                <div class="col px-0 fs-2">
                    <!-- <img v-bind:src="image" alt="" class="img-fluid rounded" style="width:100px; height:100px;" > -->
                    <i :class="icon + ' text-dark-blue text-center'" style="width:100px; height:100px; font-size:80px;"></i>
                </div>
                <div class="col-9 p-0">
                    <div class="row fw-bold why-us-card-title text-dark-blue"><p class="rp">{{title}}</p></div>
                    <div class="row"><p>{{content}}</p></div>
                </div>
            </div>
        </div>

</template>

<style scoped>

.why-us-card{
    width: 45%;
    max-width: 600px;
    font-size:10px;
}

.why-us-card-title{
        font-size:14px;
    }

@media screen and (max-width:1024px){
    .why-us-card{
        width: 45%;
        font-size:8px;
    }
    .why-us-card-title{
        font-size:12px;
    }
    .rp{
        margin-bottom: 3px;;
    }
}

@media screen and (max-width:768px){
    .why-us-card{
        width: 45%;
    }
}

@media screen and (max-width:640px){
    .why-us-card{
        width: 80%;
    }
    .why-us-card-title{
        font-size:10px;
    }
}


</style>

<script>
    export default {
        props: ['icon','title','content'],
        data() {
            return {
                
            }
        }
    }
</script>