<template>
    <div>
        <div class="container-fluid p-0" style="max-width: 1920px;">
        
        <!--navbar section start-->
        <!--navbar section end-->

        
        
        <!--services section start-->
        <div class="d-none row text-center m-0 px-0 pb-5 text-dark d-flex fst-italic py-5"><span class="fs-2"><side-line class="w-25" /> {{lang.servicePage.title[0]}} <side-line class="w-25" style="background-color:;" /></span></div>
        <!-- <services-section-en class="shadow-lg" /> -->
        <div class="row p-5 shadow-lg">      
            <div class="row d-flex justify-content-center">
                
                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[0]"
                :link="lang.servicePage.serviceCard.link[0]" 
                :title="lang.servicePage.serviceCard.title[0]" 
                :content="lang.servicePage.serviceCard.content[0]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[1]"
                :link="lang.servicePage.serviceCard.link[1]" 
                :title="lang.servicePage.serviceCard.title[1]" 
                :content="lang.servicePage.serviceCard.content[1]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[2]"
                :link="lang.servicePage.serviceCard.link[2]" 
                :title="lang.servicePage.serviceCard.title[2]" 
                :content="lang.servicePage.serviceCard.content[2]"
                />
                
                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[3]"
                :link="lang.servicePage.serviceCard.link[3]" 
                :title="lang.servicePage.serviceCard.title[3]" 
                :content="lang.servicePage.serviceCard.content[3]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[4]"
                :link="lang.servicePage.serviceCard.link[4]" 
                :title="lang.servicePage.serviceCard.title[4]" 
                :content="lang.servicePage.serviceCard.content[4]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[5]"
                :link="lang.servicePage.serviceCard.link[5]" 
                :title="lang.servicePage.serviceCard.title[5]" 
                :content="lang.servicePage.serviceCard.content[5]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[6]"
                :link="lang.servicePage.serviceCard.link[6]" 
                :title="lang.servicePage.serviceCard.title[6]" 
                :content="lang.servicePage.serviceCard.content[6]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[7]"
                :link="lang.servicePage.serviceCard.link[7]" 
                :title="lang.servicePage.serviceCard.title[7]" 
                :content="lang.servicePage.serviceCard.content[7]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[8]"
                :link="lang.servicePage.serviceCard.link[8]" 
                :title="lang.servicePage.serviceCard.title[8]" 
                :content="lang.servicePage.serviceCard.content[8]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[9]"
                :link="lang.servicePage.serviceCard.link[9]" 
                :title="lang.servicePage.serviceCard.title[9]" 
                :content="lang.servicePage.serviceCard.content[9]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[10]"
                :link="lang.servicePage.serviceCard.link[10]" 
                :title="lang.servicePage.serviceCard.title[10]" 
                :content="lang.servicePage.serviceCard.content[10]"
                />

                <service-card v-if="true"
                :image="lang.servicePage.serviceCard.image[11]"
                :link="lang.servicePage.serviceCard.link[11]" 
                :title="lang.servicePage.serviceCard.title[11]" 
                :content="lang.servicePage.serviceCard.content[11]"
                />

            </div>
        </div>
        <!--services section end-->


        <!--consulting section start-->
        <consulting-section-en class="m-5" />
        <!--consulting section end-->

        <!--become partner section start-->
        <become-partner-section v-if="false" class="m-5"/>
        <!-- become partner section end-->

        <!--check properties section start-->
        <check-properties-section v-if="false" class="m-5"/>
        <!--check properties section end-->


    </div>
        <footer-section-en></footer-section-en>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>