<template>
<div>
        <navbar-section-en></navbar-section-en>
    <div class="container-xxl p-0" style="max-width: 1920px; font-family: 'Source Sans Pro', sans-serif;" >

        <!-- CONTENT SECTION START -->
        <div class=" row text-dark">

            <div class="d-flex p-0 row justify-content-center">

                <!-- guide card left start-->
                <guide-card :class="{ 'margin-top-30' : windowTop>guideDistance, 'margin-top' : windowTop<guideDistance }"></guide-card>
                <!-- guide card left right-->

                        <img src="https://storage.viyalegal.com/website/viyalegal.com/images/interpol-cases-banner.png" alt="..." style="width:100%; object-fit:contain;" class="w-100 p-0 pt-0">
                <!--content start-->
                <div class="service-content-container d-flex justify-content-center">
                    <div class="text-dark">
                        <div class="row">
                                <div class="row text-center py-5"><span class=" fs-1"><span class="fw-bold">{{lang.serviceInterpolCases.title[0]}} </span>{{lang.serviceInterpolCases.title[1]}}</span></div>
                        </div>
                        <p>{{lang.serviceInterpolCases.content[0]}}</p>

                    </div>
                </div>
                <!--content end-->

                <!--guide card right start-->
                <guide-contact-card :class="{ 'margin-top-30' : windowTop>50, 'margin-top' : windowTop<50 }"></guide-contact-card>
                <!--guide card right end-->

            </div>

        </div>
        <!-- CONTENT SECTION END -->
        
    </div>
    <footer-section-en class="d-none"></footer-section-en>
</div>
</template>

<script>
export default {
    data() {
        return {
            windowTop: 0,
            guideDistance: 50
        };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            console.log({ top: this.windowTop });
            }
        },
        computed:{
            lang(){
                return this.$store.state.lang;
            }
        }
    }
</script>