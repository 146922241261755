<template>
    <div class="row mt-4">
        <div class="row">
            <div class="row text-dark-blue text-center fs-2 fst-italic "><span>{{lang.component.whyChooseViya.title[0]}} <span class="fw-bold">{{lang.component.whyChooseViya.title[1]}}</span></span></div>
            <div class="row text-dark-blue fw-light fs-5 text-center"><span>{{lang.component.whyChooseViya.title[2]}}</span></div>
        </div>
        <div class="row my-4 text-center fs-6 rpx-5 d-flex justify-content-center">
            <span class=" rpx-5">{{lang.component.whyChooseViya.content[0]}} <span class="text-markdown">{{lang.component.whyChooseViya.content[1]}}</span> {{lang.component.whyChooseViya.content[2]}}<span class="text-markdown">{{lang.component.whyChooseViya.content[3]}}</span> {{lang.component.whyChooseViya.content[4]}}</span>
        </div>
        <div class="row d-flex justify-content-center rpx-5">
            <div id="carouselExampleCaptions" class="carousel slide w-80" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div class="carousel-inner">
                    
                    <div class="carousel-item active">
                        <img :src="
                            lang.component.whyChooseViya.carousel.image[0]
                        " class="d-block carousel-image" alt="...">
                        <div class="carousel-caption d-md-block bg-dark-blue carousel-caption-edit">
                            <h5>{{lang.component.whyChooseViya.carousel.name[0]}}</h5>
                            <p class="text-warning" >{{lang.component.whyChooseViya.carousel.title[0]}}</p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img :src="
                            lang.component.whyChooseViya.carousel.image[1]
                        " class="d-block carousel-image" alt="...">
                        <div class="carousel-caption d-md-block bg-dark-blue carousel-caption-edit">
                            <h5>{{lang.component.whyChooseViya.carousel.name[1]}}</h5>
                            <p class="text-warning" >{{lang.component.whyChooseViya.carousel.title[1]}}</p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img :src="
                            lang.component.whyChooseViya.carousel.image[2]
                        " class="d-block carousel-image" alt="...">
                        <div class="carousel-caption d-md-block bg-dark-blue carousel-caption-edit">
                            <h5>{{lang.component.whyChooseViya.carousel.name[2]}}</h5>
                            <p class="text-warning" >{{lang.component.whyChooseViya.carousel.title[2]}}</p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img :src="
                            lang.component.whyChooseViya.carousel.image[3]
                        " class="d-block carousel-image" alt="...">
                        <div class="carousel-caption d-md-block bg-dark-blue carousel-caption-edit">
                            <h5>{{lang.component.whyChooseViya.carousel.name[3]}}</h5>
                            <p class="text-warning" >{{lang.component.whyChooseViya.carousel.title[3]}}</p>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img :src="
                            lang.component.whyChooseViya.carousel.image[4]
                        " class="d-block carousel-image" alt="...">
                        <div class="carousel-caption d-md-block bg-dark-blue carousel-caption-edit">
                            <h5>{{lang.component.whyChooseViya.carousel.name[4]}}</h5>
                            <p class="text-warning" >{{lang.component.whyChooseViya.carousel.title[4]}}</p>
                        </div>
                    </div>
                    

                </div>
                <button class="carousel-control-prev " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .carousel-image{
        object-fit: cover;
        height:800px; 
        width:100%; 
        overflow-y:hidden;
    }

    @media screen and (max-width:1024px) {
        .carousel-image{
            object-fit: contain;
            height: 80%;
        }

        .carousel-caption-edit{
            height: 80px;
        }
        
    }
</style>

<script>
export default {
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>