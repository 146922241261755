

<template v-if="false">
<div>
    <div class="container-fluid p-0 " style="max-width: 1920px; background-color:white;">
            <div class="row p-0 m-0">
                            <!-- <img class="p-0 w-100" style="object-fit:cover;" src="../../src/assets/images/contact-banner.png" alt=""> -->
            <div class="row p-0 mb-5 py-0 d-flex justify-content-center">
                <!--ADRESS SECTION START-->
                <div class="row p-0 text-dark-blue w-80">
                        <p class=" m-0 fs-14 mt-3" style="color:#979797;"><a href="viyalegal.com">{{lang.contactPage.title[0]}}</a> > {{lang.contactPage.title[1]}}</p>
                        <p style="font-size:36px;" class=" fw-light text-dark-blue border-bottom">{{lang.contactPage.title[1]}}</p>

                        <div class="row d-flex justify-content-between p-0 mb-3">
                            <address class="shadow-sm p-0 rounded my-0" style="width:400px; font-family: 'Source Sans Pro', sans-serif;">
                                
                                <div class="address-body border border-1 border- p-3 w-100 h-100">
                                    <p class="fs-5 m-0 mb-2" style="color:#0b7aaa;"><i class="fa-solid fa-location-dot pe-1"></i> {{lang.contactPage.title[2]}}</p>
                                    <div class="row fs-6 px-1">
                                        <a class="" href="https://maps.google.com/maps/dir//Viya+Legal+Hukuk+%26+Dan%C4%B1%C5%9Fmanl%C4%B1k+B%C3%BCrosu+Viya+Legal+Madenler+Mah.+%C4%B0dealistkent+Cad.+%C4%B0dealistkent+Sitesi+2.+Etap+D:No:4+a%2Fb+34776+%C3%9Cmraniye%2F%C4%B0stanbul/@41.0139313,29.1780052,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x14cacf38d941b32b:0x35f779f2d66da84f">
                                        {{lang.contactPage.content[0]}} <br> {{lang.contactPage.content[1]}}
                                        </a>
                                    </div>
                                </div>

                            </address>

                            <div class="shadow-sm p-0 rounded my-0" style="width:400px; font-family: 'Source Sans Pro', sans-serif;">
                                <div class="address-body border border-1 border- p-3 w-100 h-100">
                                    <p class="fs-5 m-0 mb-2" style="color:#2d9ccc;"><i class="fa-solid fa-phone pe-1"></i> {{lang.contactPage.title[3]}}</p>
                                    <div class="row fs-6"><a class=" p-0" :href="'tel:'+ lang.contactPage.content[2]">{{lang.contactPage.content[2]}}</a></div>
                                </div>
                            </div>

                            <div class="shadow-sm p-0 rounded my-0" style="width:400px; font-family: 'Source Sans Pro', sans-serif;">
                                <div class="address-body border border-1 border- p-3 w-100 h-100">
                                    <p class="fs-5 m-0 mb-2" style="color:#2d9ccc;"><i class="fa-solid fa-envelope pe-1"></i> {{lang.contactPage.title[4]}}</p>
                                    <div class="row fs-6"><a class=" p-0" :href="'mailto:'+lang.contactPage.content[3]">{{lang.contactPage.content[3]}}</a></div>
                                </div>
                            </div>
                        </div>


                        <!--MAP LOCATION START-->
                        <div class="two">
                            <iframe class="p-0 m-0 w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2038.503363287064!2d29.17726906073629!3d41.01398881891445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35f779f2d66da84f!2zVml5YSBMZWdhbCBIdWt1ayAmIERhbsSxxZ9tYW5sxLFrIELDvHJvc3U!5e0!3m2!1str!2str!4v1658099759570!5m2!1str!2str"  height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div class="two">
                            <img src="../../src/assets/images/building.png" alt="" class="w-100" style="height:450px; object-fit:cover;">
                        </div>
                        <div class="two d-flex justify-content-center ps-0"><a href="https://wa.me/message/VLPMWUAJXBKBB1" class="py-3 my-1 w-100 alig-self-center text-white text-center bg-success border-0">Whatsapp</a></div>
                        <div class="two d-flex justify-content-center pe-0"><a href="tel:+905448392408" class="py-3 my-1 w-100 alig-self-center text-white text-center bg-primary border-0">{{lang.contactPage.content[4]}}</a></div>
                        <!--MAP LOCATION END-->

                </div>
                <!--ADDRESS SECTION END-->
                <staff-section></staff-section>

                <!--consulting-section-start-->
                <consulting-section-en></consulting-section-en>
                <!--consulting section end-->
            
            </div>
        </div>

    </div>
    <footer-section-en></footer-section-en>
</div>
</template>

<script>
export default {
    computed: {
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>