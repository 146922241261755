<template>
    <!--footer section start-->
        <footer>
            <div class="row bg-dark-blue text-center text-light py-1 fs-10"><span>Viya Legal, Citizenship & Investment Consultant Services</span></div>
            <div v-if="false" class="contaner-fluid" style="background-color: #1b2e4d;">
                <div class="row ms-5 me-5 text-light p-5" style="font-family: 'Montserrat', sans-serif;">
                    <div class="col-xl-4">
                        <div class="row fs-2">
                            <p><span class="fw-bold">VIYA</span> <br> Legal & Citizenship Services</p>
                        </div>
                        <div class="row"><p>Idealist Kent Sitesi Etap 2, Villa Number 4, Madenler Mahallesi, Ümraniye, İstanbul – 34776</p></div>
                        <div class="row mt-3">
                            <a class="text-light rmv-underline d-inline"  href="tel:+902164155315">
                                <div class="row">
                                    <div class="col-1 text-center"><img src="" alt="" class="img-fluid"></div>
                                    <div class="col-5">+90 216 415 53 15</div>
                                </div>
                            </a>
                        </div>
                        <div class="row">
                            <a class="text-light rmv-underline"  href="mailto:info@viyalegal.com">
                                <div class="row">
                                    <div class="col-1 text-center"><img src="" alt="" class="img-fluid"></div>
                                <div class="col-5">info@viyalegal.com</div>
                                </div>
                            </a>
                        </div>
                        <div class="row mt-3 d-flex justify-content-start fw-bold">
                            <div class="col-1 ms-5 bg-primary rounded text-center">f</div>
                            <div class="col-1 ms-5 bg-primary rounded text-center">t</div>
                            <div class="col-1 ms-5 bg-primary rounded text-center">in</div>
                            <div class="col-1 ms-5 bg-primary rounded text-center">YT</div>
                        </div>
                    </div>
                    <div class="col-xl-3 fs-16 fst-italic">
                        <div class="row">
                            <h4 class="text-primary">Turkish Citizenship by Investment</h4>
                        </div>
                        <div class="row">
                            <ul>
                                <li><a class="text-light rmv-underline" href="#">Services</a></li>
                                <li><a class="text-light rmv-underline" href="#">Blog</a></li>
                                <li><a class="text-light rmv-underline" href="#">About</a></li>
                                <li><a class="text-light rmv-underline" href="#">Contact</a></li>
                                <li><a class="text-light rmv-underline" href="#">Fluently Asked Questions (FAQ)</a></li>
                                <li><a class="text-light rmv-underline" href="#">partnership</a></li>
                                <li><a class="text-light rmv-underline" href="#"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 fs-16 fst-italic">
                        <div class="row">
                            <h4 class="text-primary">Properties In</h4>
                        </div>
                        <div class="row">
                            <ul>
                                <li><a class="text-light rmv-underline" href="#">İstanbul</a></li>
                                <li><a class="text-light rmv-underline" href="#">İzmir</a></li>
                                <li><a class="text-light rmv-underline" href="#">Antalya</a></li>
                                <li><a class="text-light rmv-underline" href="#">Ankara</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 fs-16 fst-italic"><div class="row">
                        <h4 class="text-primary">Lorem ipsum dolor sit amet</h4>
                    </div>
                    <div class="row">
                        <ul>
                            <li><a class="text-light rmv-underline" href="#">foo by foofer</a></li>
                        </ul>
                    </div></div>
                </div>
            </div>
        </footer>
        <!--footer section end-->
</template>

<style scoped>
    .fs-16{
        font-size: 16px;
    }
    .fs-14{
        font-size: 14px;
    }
    .fs-12{
        font-size: 12px;
    }
</style>