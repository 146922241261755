<template>
    <div class="row m-0 border-bottom p-3 shadow-lg" style="background-image: url(https://metropolitan.realestate/wp-content/themes/framework/assets/images/dotbg.png); background-attachment: fixed;">

            <div class="row text-center fs-1 m-0">
                <div class="row m-0 mb-5 fst-italic"><h3><side-line /> CLIENT FEEDBACKS <side-line /></h3></div>
            </div>
            <!-- Card Section Start -->
            <div v-if="false" class="row position-relative overflow-hidden"  style="max-height: 390px;">
                <div class="row pt-2 pb-2 d-flex">

                    <div class="card mb-1 me-2 ms-2" style="width:500px;">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card mb-3 me-2 ms-2" style="width:500px;">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://randomuser.me/api/portraits/men/91.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="row pt-2 pb-2">

                    <div class="card mb-3 me-2 ms-2" style="width:500px; ">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://realeast.biz/wp-content/themes/real/assets/img/manager.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3 me-2 ms-2" style="width:500px; ">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://realeast.biz/wp-content/themes/real/assets/img/manager.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3 me-2 ms-2" style="width:500px; ">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://realeast.biz/wp-content/themes/real/assets/img/manager.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3 me-2 ms-2" style="width:500px; ">
                        <div class="row g-0 p-2">
                            <div class="col-md-4">
                                <img src="https://realeast.biz/wp-content/themes/real/assets/img/manager.jpg" alt="..." class="img-thumbnail rounded-circle" style="width: 150px; height: 150px;">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            <!-- Card Section End -->

            <div class="row">
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="d-flex justify-content-center">
                                <feedback-card></feedback-card>
                                <feedback-card></feedback-card>
                                <feedback-card></feedback-card>
                            </div>
                        </div>

                        
                    </div>

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            
        
    </div>
</template>