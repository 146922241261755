<template>
    <div class="row m-0 pt-5" style="background-image: url(https://storage.viyalegal.com/website/viyalegal.com/images/dotbg.png);">
            <div class="row m-0 p-0 text-center fst-italic text-dark-blue">
                <h3><side-line /> {{lang.component.staffSection.title[0]}} <side-line /></h3>
            </div>
            <div class="row m-0 rp-5 d-flex justify-content-center">


                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[0]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[0]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[0]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[0]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[0]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[0]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[0]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[0]}}
                        </p></div>
                    </div>
                </div>

                <!-- test -->

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[1]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[1]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[1]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[1]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[1]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[1]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[1]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[1]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[2]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[2]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[2]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[2]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[2]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[2]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[2]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[2]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[3]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[3]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[3]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[3]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[3]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[3]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[3]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[3]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[4]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[4]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[4]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[4]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[4]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[4]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[4]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[4]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[5]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[5]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[5]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[5]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[5]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[5]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[5]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[5]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[6]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[6]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[6]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[6]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[6]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[6]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[6]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[6]}}
                        </p></div>
                    </div>
                </div> 

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[7]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[7]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[7]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[7]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[7]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[7]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[7]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[7]}}
                        </p></div>
                    </div>
                </div>

                <div class="card m-2 shadow if-1024-text" style="width: 450px;">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4 text-center"><img :src="lang.component.staffSection.staff.image[8]" alt="..." class="img-fluid rounded-circle" style="width: 100px; height:100px; object-fit:cover;"></div>
                            <div class="col-md-8 d-flex justify-content-center">
                                <div class="row align-self-center text-center">
                                    <div class="row m-0 fw-bold"><span>{{lang.component.staffSection.staff.name[8]}}</span></div>
                                    <div class="row m-0"><span>{{lang.component.staffSection.staff.title[8]}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between fs-14"><p class="text-center mb-1"><a :href="'mailto:' + lang.component.staffSection.staff.email[8]"><i class="fa-solid fa-envelope pe-1"></i> {{lang.component.staffSection.staff.email[8]}}</a></p><p class="text-center"><a :href="'tel:' + lang.component.staffSection.staff.phone[8]"><i class="fa-solid fa-phone pe-1"></i> {{lang.component.staffSection.staff.phone[8]}}</a></p></div>
                        <div class="row px-3 text-center " style="font-weight:200px;"><p>
                            {{lang.component.staffSection.staff.content[8]}}
                        </p></div>
                    </div>
                </div>

            </div>
        </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>