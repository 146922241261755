<template>
    <div>

        <div class="main-container container-fluid p-0" style="max-width: 1920px;">
        
        <button @click="audio_control" class="position-fixed end-0 border-0 rounded-circle" style="width:50px; height: 50px; top:30%; z-index:6; opacity:0.7;"><i class="fa-solid " :class="{'fa-volume-high':audio_status, 'fa-volume-xmark' : !audio_status}" ></i></button>
        <!-- Modal -->
        <div @click="audio_control" v-if="true" class="modal fade" id="consultationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog bg-transparent">
                        <div class="modal-content bg-transparent border-0">
                            <div @click="audio_control" class="d-flex justify-content-end">
                                <button type="button" @click="audio_control" class="btn-close" style="background-image:url(https://www.storage.viyalegal.com/website/viyalegal.com/images/close.png); background-size:cover; opacity:1; width:30px; height:30px;" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div class="row w-100 d-flex justify-content-center">
                                <img style="width:300px; height:auto;" src="../../src/assets/images/modal-family.png" alt="">
                            </div>
                            <div class="modal-body text-dark-blue p-0 m-0 bg-light">
                                <div class="row fw-bold fs-5 py-2 bg-dark-blue text-light text-center rounded-bottom"><span style="font-family: 'Anek Latin', sans-serif;">First Step of Your New Life</span></div>
                                <div class="row p-4 pb-0">
                                    <span class="fs-6">
                                        Viya office is not only a firm, a name you can trust. It's an intelligent move Dedicated to results.
                                        We will help you to live without borders, without limitations of passport. Navigate your future with VIYA for the secure and successful path that waits for you because great things happen when VIYA gets involved. 
                                        “VIYA Office “ For The Results You Deserve. To secure you & your family.
                                    </span>
                                    <span class="fs-6 my-3 bg-dark-blue rounded-pill text-light text-center p-2">
                                        <a href="https://wa.me/message/VLPMWUAJXBKBB1" class="text-light rmv-underline fw-bold" style="font-family: 'Anek Latin', sans-serif;">GET FREE CONSULTATION NOW</a>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
        </div>
        <!-- Modal section END -->

        <!-- VIDEO SECTION START -->
        <div class="row shadow position-relative banner_video" style="height:840px; overflow-y:hidden; overflow-x:hidden;">
            <!-- cover -->
            <div class="position-absolute top-0 left-0 w-100 h-100 bg-dark" style="z-index:5; opacity:0.2;"></div>
            <!-- video container -->
            <div class="d-flex justify-content-center position-absolute top-0 left-0 w-100 h-100 p-0">
                <video class="w-100 position-absolute" style="min-width:1600px; min-height:1800px; top:-480px;" controls muted autoplay loop>
                    <source src="../assets/media/video.mp4" type="video/mp4">
                </video>
                <!-- <iframe class="w-100 position-absolute" style="min-width:1600px; min-height:1800px; top:-480px;" allow="autoplay;" src="https://www.youtube.com/embed/eV6lTEY95yY?playlist=eV6lTEY95yY&autoplay=1&controls=0&disablekb=1&fs=0&iv_load_policy=3&loop=1&modestbranding=1&playsinline=1&rel=0&showinfo=0&mute=1&enablejsapi=1&origin=https%3A%2F%2Fwww.viyalegal.com&widgetid=1" frameborder="0" loop></iframe> -->
            </div>
        </div>
        <!-- VIDEO SECTION END -->

        <!-- OPTIONS FOR CBI SECTION START -->
        <div class="row d-flex justify-content-center position-relative cbi" style="z-index:12;">
            <div class="row bg-dark-blue  pb-3 px-0 shadow-lg" style="width:85%;">
                <div class="row text-center my-4 text-light"><span><h3>{{lang.homepage.title.optionsForCBI[0]}}</h3></span></div>

                <!-- CBI OPTIONS SECTION START -->
                <div class="row">
                    <div class="row d-flex justify-content-center">

                        <cbi-options-card icon="fa-solid fa-money-bill-trend-up" :title="lang.homepage.title.optionsForCBI[1]" :content="lang.homepage.content.optionsForCBI[0]" />
                        <cbi-options-card icon="fa-solid fa-city" :title="lang.homepage.title.optionsForCBI[2]" :content="lang.homepage.content.optionsForCBI[1]" />
                        <cbi-options-card icon="fa-solid fa-person-walking-luggage" :title="lang.homepage.title.optionsForCBI[3]" :content="lang.homepage.content.optionsForCBI[2]" />
                        <cbi-options-card icon="fa-solid fa-question" :title="lang.homepage.title.optionsForCBI[4]" :content="lang.homepage.content.optionsForCBI[3]"  />

                    </div>
                </div>
                <!-- CBI OPTIONS SECTION END -->

            </div>
        </div>
        <!-- OPTIONS FOR CBI SECTION END -->
        <!--cbi info section start-->
        <div class="d-flex justify-content-center">
            <div class="row mx-0 mb-5 text-dark-blue" style="font-family: 'Montserrat', sans-serif;">

                <div class="row text-center  p-0 m-0 my-5 text-dark-blue">
                    <span class="fs-2-1024 fw-bold">{{lang.homepage.title.turkeyCitizenshipByCBIProgram[0]}}</span>
                </div>

                <div class="row text-center rpx-5">
                    <span class="rpx-5 fs-6-1024"> <span class="fw-bold">{{lang.homepage.content.turkeyCitizenshipByCBIProgram[0]}}</span> {{lang.homepage.content.turkeyCitizenshipByCBIProgram[1]}}</span>
                </div>

                <!-- FLAGS SECTION START -->
                <div v-if="true" class="row d-flex justify-content-center my-4" style="opacity:0.8;">
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/1280px-Flag_of_Iraq.svg.png" alt="Iraq" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Flag_of_Jordan.svg/2560px-Flag_of_Jordan.svg.png" alt="Jordan" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Palestine.svg/1280px-Flag_of_Palestine.svg.png" alt="Palestine" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/800px-Flag_of_Egypt.svg.png?20220227110228" alt="Egypt" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Libya.svg/1280px-Flag_of_Libya.svg.png" alt="Libya" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Flag_of_Yemen.svg/800px-Flag_of_Yemen.svg.png" alt="Yemen" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/2560px-Flag_of_Iran.svg.png" alt="Iran" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Flag_of_Afghanistan_%282013%E2%80%932021%29.svg/2560px-Flag_of_Afghanistan_%282013%E2%80%932021%29.svg.png" alt="Afghanistan" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/2560px-Flag_of_India.svg.png" alt="India" >
                    <img class="m-1 p-0 rounded country-flag" src="https://cdn.britannica.com/46/3346-004-D3BDE016/flag-symbolism-Pakistan-design-Islamic.jpg" alt="Pakistan" >
                    <img class="m-1 p-0 rounded country-flag" src="https://file.wikipediam.org/wikipedia/commons/thumb/f/f9/Flag_of_Bangladesh.svg/2560px-Flag_of_Bangladesh.svg.png" alt="Bangladesh" >
                    <img class="m-1 p-0 rounded country-flag" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/2560px-Flag_of_Russia.svg.png" alt="Russia" >
                    <img class="m-1 p-0 rounded country-flag" src="https://cdn.britannica.com/90/7490-004-BAD4AA72/Flag-China.jpg" alt="China" >
                </div>
                <!-- FLAGS SECTION END -->
                
                <div class="row rp-5 pt-0 m-0 pb-0 ">
                    <div class="row rpx-5 m-0 d-flex justify-content-center">
                        <div class="if-1024-text rpx-5 ">
                            <p class="if-text-1024"> <span class="fw-bold">{{lang.homepage.content.turkeyCitizenshipByCBIProgram[2]}}</span> {{lang.homepage.content.turkeyCitizenshipByCBIProgram[3]}} <span class="fw-bold">{{lang.homepage.content.turkeyCitizenshipByCBIProgram[4]}}</span> {{lang.homepage.content.turkeyCitizenshipByCBIProgram[5]}} </p>
                            <p><span class="fw-bold if-text-1024">{{lang.homepage.content.turkeyCitizenshipByCBIProgram[6]}}</span>{{lang.homepage.content.turkeyCitizenshipByCBIProgram[7]}}</p>
                            <div class="row d-flex justify-content-center"><button class="btn btn-secondary bg-dark-blue w-100" onclick="location.href='/citizenship-service'">{{lang.homepage.content.turkeyCitizenshipByCBIProgram[8]}}</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--cbi info section end-->

        
        
        <!--why viya section start-->
        <why-viya-section v-if="false"></why-viya-section>
        <!--why viya section end-->

        <viya-with-numbers></viya-with-numbers>

        <!-- WHY US SECTION START -->
        <why-us-section></why-us-section>
        <!-- WHY US SECTION END -->

        <!--property showcase section start-->
        <property-showcase-section v-if="false"></property-showcase-section>
        <!--property showcase section end-->

        <!--client feedback section start-->
        <client-feedbacks-section v-if="false"></client-feedbacks-section>
        <!--client feedback section end-->

        <!--staff section start-->
        <staff-section></staff-section>
        <!--staff section end-->

        <consulting-section-en class="p-5"></consulting-section-en>
        <wp-button></wp-button>

    </div>
        <footer-section-en></footer-section-en>
    </div>
    
    

</template>



<style scoped>
        .country-flag{
            width: 130px;
            height: auto;
            object-fit:cover;
        }

        .banner-video{
            height:200px;
            width:auto;
        }
</style>

<script>
export default {
    data:function() {
        return{
            audio_status:false,
            audio: new Audio('./classical-ottoman-music.mp3')
        }
    },
    methods:{
        audio_control(){
            var getin = this
            if(this.audio_status == false){
                getin.audio.play();
                getin.audio_status = true;
            }
            else
            {
                getin.audio.pause();
                getin.audio_status = false;
            }
        },
        play_banner(){
            player.playVideo()
        },
        modalTrigger(){
            var proxyVar = this;
            if(this.$store.state.homeModalTriggeredStatus == false)
            {
                this.$store.state.homeModalTriggeredStatus = true;
                $(document).ready(function(){
                    $("#consultationModal").modal('show');
                    console.log("Hi stranger, wasn't expecting to see you here. \nWould you get some turkish coffee? ☕☕☕");
                });
            }
        }
    },
    mounted(){
        this.modalTrigger();
    },
    computed:{
        lang(){
            return this.$store.state.lang;
        }
    }
}
</script>