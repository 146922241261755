<template>
    <div>
        <div v-if="false" class="card mb-1 me-2 ms-2 w-100" >
        <div class="row g-0 p-2">
            <div class="col-md-4">
                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="..." class="img-thumbnail p-0 h-100 w-auto" >
            </div>
                <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                </div>
            </div>
        </div>
    </div>
    <div v-if="false" class="card mb-1 me-2 ms-2" >
        <div class="row g-0 p-2">
            <div class="row d-flex justify-content-center">
                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="..." class="img-thumbnail rounded-circle w-100" >
            </div>
            <div class="row">
                <div class="card-body">
                    <h5 class="card-title">Rakhas Al Mahamdi</h5>
                    <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                    <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
                </div>
            </div>
        </div>
    </div>

    <div class="card p-3 mx-2" style="width:400px;">
        <div class="row text-start fs-2 text-navy"><i class="fa-solid fa-quote-left"></i></div>
        <div class="row d-flex justify-content-center"><img src="https://randomuser.me/api/portraits/women/21.jpg" alt="..." class="img-thumbnail rounded-circle p-0 " style="width:200px; height:200px;"></div>
        <div class="row">
            <div class="card-body p-5">
                <h5 class="card-title mb-0">Rakhas Al Mahamdi</h5>
                <p class="card-text"><small class="text-muted fst-italic">CEO of Emaar</small></p>
                <p class="card-text">"Very good service, thanks to Mr.İsrafil and Viya Team."</p>
                <p class="card-text"><small class="text-muted fst-italic">22.03.2022</small></p>
            </div>
        </div>
        <div class="row text-end fs-2 text-navy"><i class="fa-solid fa-quote-right"></i></div>
    </div>

    </div>
</template>

<script>

</script>