<template>
    <div class="row pt-3 text-dark-blue px-5">
            <div class="accordion accordion-flush" id="faqAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed bg-dark-blue text-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        What are the main options of getting citizenship in Turkey by purchasing a property?
                    </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> there are two main methods of through which one can acquire Turkish citizenship.</p>
                        <p>a-) By purchasing $ 250,000 worth of property which makes the investor eligible for Turkish citizenship</p>
                        <p>b-) By purchasing a property with a capital of less than $250,000 which makes the investor, his wife and children under the age of 18 eligible for residency. If they stay in Turkey for 5 years, they can become permanent residents and citizens. It is worth mentioning that in order to apply for permanent residence and citizenship, you should be present in Turkey 4 years out of 5 years.</p>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Can the residence taken after the purchase of the property be extended?
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> Yes, the residence permit for buying a property can be extended.</p>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        How many members of a family can apply for citizenship?
                    </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> Investor, spouse(s) and children aged 18 and under.</p>
                        <p> Children must be <span class="fw-bold">under 19 years old</span> during the application process. </p>
                    </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                        Can the residence taken after the purchase of the property be extended?
                    </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> Yes, the residence permit for buying a property can be extended.</p>
                    </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                        Is the property deed purchased by foreigners different from the property deed of a Turkish citizen?
                    </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> No, It is strongly emphasized that there is no difference between a Turkish citizen and a foreigner, both in terms of documents and legal matters.</p>
                    </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSeven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                        If you buy in installments, how much interest will be added to the purchase amount?
                    </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p><span class="fw-bold">Answer:</span> Installments are Interest-free.</p>
                    </div>
                    </div>
                </div>


            </div>
        </div>
</template>

