<template>
  <div>
    <div class="container-xxl p-0" style="max-width: 1920px;">
      <navbar-section-en></navbar-section-en>

      <!-- CONTENT SECTION START -->
      <div class="row text-dark">
        <div class="d-flex p-0 row justify-content-center">
          <!-- guide card left start-->
          <guide-card
            :class="{
                'margin-top-30' : windowTop>guideDistance, 
                'margin-top' : windowTop<guideDistance
                 }"
            />
          <!-- guide card left right-->

          <img
            src="https://storage.viyalegal.com/website/viyalegal.com/images/citizenship-banner.png"
            alt="..."
            style="width:100%; object-fit:contain;"
            class="w-100 p-0 pt-0"
          />
          <!--residency content start-->
          <div class="service-content-container d-flex justify-content-center">
            <div class="text-dark">
              <p class="fs-2" style="color:#004792;"><span class="fw-bold">{{lang.serviceCitizenship.title[0]}}</span> {{lang.serviceCitizenship.title[1]}}</p>
              <p>{{lang.serviceCitizenship.content[0]}}</p>
              <br>
              <p class="fw-bold fs-5">{{lang.serviceCitizenship.title[2]}}</p>
              <p>{{lang.serviceCitizenship.content[1]}}</p>
              <p>{{lang.serviceCitizenship.content[2]}}</p>
              <p>{{lang.serviceCitizenship.content[3]}}</p>
              <br>
              <p class="fw-bold fs-4">{{lang.serviceCitizenship.title[3]}}</p>
              <p>{{lang.serviceCitizenship.content[4]}}</p>

              <p>
                <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[4]}}</span>
              </p>
              <p>{{lang.serviceCitizenship.content[5]}}</p>

              <p>
                <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[5]}}</span>
              </p>
                <br>
              <ul class="rpx-5">
                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  {{lang.serviceCitizenship.title[6]}}
                  <span class="fw-normal">{{lang.serviceCitizenship.title[7]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p class="fs-5">{{lang.serviceCitizenship.title[8]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[6]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>{{lang.serviceCitizenship.content[7]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[8]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[9]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                    <br>
                    <p class="fs-5 fw-bold">{{lang.serviceCitizenship.title[9]}}</p>
                    <ul style="list-style-type:circle">
                      <li>
                        <span><span class="fw-bold">{{lang.serviceCitizenship.title[10]}}</span> <br> {{lang.serviceCitizenship.content[10]}}</span>
                      </li>
                      <li>
                        <span><span class="fw-bold">{{lang.serviceCitizenship.title[11]}}</span> <br> {{lang.serviceCitizenship.content[11]}}</span>
                      </li>
                      <li>
                        <span><span class="fw-bold">{{lang.serviceCitizenship.title[12]}}</span> <br> {{lang.serviceCitizenship.content[12]}}</span>
                      </li>
                    </ul>
                  </div>
                </li>

                <br>

                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[13]}}</span>
                  <span class="fw-normal">{{lang.serviceCitizenship.title[14]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p class="fs-5">{{lang.serviceCitizenship.title[15]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[13]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[14]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[15]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[16]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                    <br>
                    <p class="fs-5">{{lang.serviceCitizenship.title[16]}}</p>
                    <ul style="list-style-type:circle">
                      <li>
                        <span>{{lang.serviceCitizenship.content[17]}}</span>
                      </li>
                      <li>
                        <span>{{lang.serviceCitizenship.content[18]}}</span>
                      </li>
                      <li>
                        <span>{{lang.serviceCitizenship.content[19]}}</span>
                      </li>
                      <li>
                        <span>{{lang.serviceCitizenship.content[20]}}</span>
                      </li>
                      <li>
                        <span>{{lang.serviceCitizenship.content[21]}}</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <br>

                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[17]}}</span>
                  <span class="fw-normal">{{lang.serviceCitizenship.title[18]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p class="fs-5">{{lang.serviceCitizenship.title[19]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[22]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[23]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[24]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[25]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
                <br>
                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[20]}}</span>
                  <span class="fw-normal">{{lang.serviceCitizenship.title[21]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p class="fs-5">{{lang.serviceCitizenship.title[22]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[26]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[27]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[28]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[29]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
                <br>
                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[23]}}</span>
                  <span class="fw-normal">{{lang.serviceCitizenship.title[24]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p class="fs-5">{{lang.serviceCitizenship.title[25]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[30]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[31]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[32]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
                <br>
                <li class="fw-bold fs-4" style="list-style-type:alpha-lower;">
                  <span class="fw-bold fs-4">{{lang.serviceCitizenship.title[26]}}</span>
                  <span class="fw-normal">{{lang.serviceCitizenship.title[27]}}</span>
                  <div class="li-body fs-6 fw-normal">
                    <p
                      class="fs-5"
                    >{{lang.serviceCitizenship.title[28]}}</p>
                    <ul style="list-style-type:circle;">
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[33]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[34]}}
                          <br>
                        </span>
                      </li>
                      <li>
                        <span>
                          {{lang.serviceCitizenship.content[35]}}
                          <br>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
                <br>
              </ul>
              <p class="fw-bold fs-2">{{lang.serviceCitizenship.title[29]}}</p>
              <p class="fw-bold fs-5">{{lang.serviceCitizenship.title[30]}}</p>
              <p>{{lang.serviceCitizenship.content[36]}}</p>
              <br>
              <p class="fw-bold fs-5">{{lang.serviceCitizenship.title[31]}}</p>
              <p>
                <span class="fw-bold">{{lang.serviceCitizenship.title[32]}}</span>
                <br>
                <span class="fw-bold">a)</span> {{lang.serviceCitizenship.content[37]}}
                <br>
                <span class="fw-bold">b)</span> {{lang.serviceCitizenship.content[38]}}
                <br>
                <span class="fw-bold">c)</span> {{lang.serviceCitizenship.content[39]}}
              </p>
              <br>
              <p class="fw-bold fs-5">{{lang.serviceCitizenship.title[33]}}</p>
              <p>{{lang.serviceCitizenship.content[40]}}</p>
              <br>
            </div>
          </div>
          <!--residency content end-->

          <!--guide card right start-->
          <guide-contact-card
            :class="{ 'margin-top-30' : windowTop>50, 'margin-top' : windowTop<50 }"
          ></guide-contact-card>
          <!--guide card right end-->
        </div>
      </div>
      <!-- CONTENT SECTION END -->
    </div>
    <footer-section-en></footer-section-en>
  </div>
</template>

<script>
export default {
    data() {
        return {
            windowTop: 0,
            guideDistance: 50
        };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            console.log({ top: this.windowTop });
            }
        },
        computed:{
          lang(){
            return this.$store.state.lang;
          }
        }
    }
</script>